import React from 'react';
import Backplane from "./backplane"
import { blueprint } from '../../datamodels';

export default function Backplanes({scene, model, material, boards, width, grid, thickness}) {
  let totalHeight = 0
  const colSize = (width - blueprint.thickness) / grid
  return (
    boards.map((board,y) => {
      const backplaneModel = model[y] || {full:false,cover:[]}
      let el = null
      if(backplaneModel.full === true) {
        el = <Backplane key={'full.'+ y} scene={scene} material={material.big} width={width - 2 * thickness} height={board.height - thickness} xPos={0 + thickness} yPos={totalHeight + thickness} zPos={0.008} />
      } else {
        //needs to cover two cols if vboard is invisible
        el = []
        for(let x = 0; x < grid; x++) {
          const wall = board.cols[x] || {visible:true}
          const nextWall = board.cols[x + 1] || {visible:true}
          const multiplier = wall.visible ? 1 : nextWall.visible ? 2 : 3 
          if(backplaneModel.cover[x]) el.push(<Backplane key={y+'.'+x} scene={scene} material={material.small_2} width={colSize * multiplier - thickness} height={board.height - thickness} xPos={x * colSize + thickness} yPos={totalHeight + thickness} zPos={0.008} />)
          x += multiplier - 1
        }
      }
      totalHeight += board.height
      return el
    })
  )
}