import React from 'react'
import './perspective_controls.scss'
import FrontIcn from './viewIcn-front@2x.png'
import SideIcn from './viewIcn-side@2x.png'
import IsoIcn from './viewIcn-iso@2x.png'
import DoorsIcn from './viewIcn-doors@2x.png'
import { useStore } from '../../store/store'


function PerspectiveControls(props) {
    const showDoors = useStore(state => state.doors.setVisible)
    const changePerspective = useStore(state => state.setPerspective)

    return (
        <div className="perspective-controls">
            <span id="front-btn" onClick={() => {changePerspective(0); showDoors(false)}}><div className="tooltip"><img alt="icon to show shelf in front view" src={FrontIcn} /><span className="tooltiptext">ohne Schiebetüren</span></div></span>
            <span id="doors-btn" onClick={() => {changePerspective(0); showDoors(true)}}><div className="tooltip"><img alt="icon to blend doors in" src={DoorsIcn} /><span className="tooltiptext">Frontansicht</span></div></span>
            <span id="iso-btn" onClick={() => {changePerspective(1); showDoors(true)}}><div className="tooltip"><img alt="icon to show shelf in iso view" src={IsoIcn} /><span className="tooltiptext">Schrägansicht</span></div></span>
            <span id="side-btn" onClick={() => {changePerspective(2); showDoors(true)}}><div className="tooltip"><img alt="icon to show shelf in side view" src={SideIcn} /><span className="tooltiptext">Seitenansicht</span></div></span>
            <span id="back-btn" onClick={() => {changePerspective(3); showDoors(true)}}><div className="tooltip"><img alt="icon to show shelf in backside view" src={FrontIcn} /><span className="tooltiptext">Rückansicht</span></div></span>
        </div>
    )
}

export default PerspectiveControls
