import React from 'react';
import * as THREE from 'three'

export default class Drawer extends React.Component {

  // innerSizes are calculated here while width & height of drawer is set from outer component

  constructor(props) {
    super(props)
    this.frontThickness = 0.003
    this.initObject()
  }
  
  initObject() {
    const {material, width,height,depth,xPos,yPos} = this.props
    const innerMaterial = this.props.innerMaterial || material
    this.material = material
    this.geometry  = new THREE.BoxBufferGeometry(1,1,1)
    this.geometry.rotateX(Math.PI * 0.5)
    this.geometry.translate(0.5,0.5,0.5)
    this.front = new THREE.Mesh(this.geometry,material)
    this.box = new THREE.Mesh(this.geometry,innerMaterial)
    
    this.drawer = new THREE.Group()
    this.drawer.add(this.front, this.box)
    this.scaleObject(width,height,depth)
    this.setPos(xPos,yPos)
  }
  
  scaleObject(width,height,depth) {
    this.front.scale.set(width,height,this.frontThickness)
    this.front.position.z = depth - this.frontThickness - 0.025
    this.box.scale.set(width - 0.02,height - 0.06,depth - this.frontThickness - 0.055)
    this.box.position.x = 0.01
    this.box.position.y = 0.03
    this.box.position.z = 0.03
  }
  
  setPos(xPos,yPos,zPos) {
    this.drawer.position.set(xPos || 0, yPos || 0, zPos || 0)
  }
  setMaterial(newMat, innerMat) {
    this.front.material = newMat
    this.box.material = innerMat
  }
  
  componentDidMount() {
    this.props.scene.add(this.drawer)
  }
  componentDidUpdate(prevProps) {
    this.scaleObject(this.props.width, this.props.height, this.props.depth)
    this.setPos(this.props.xPos,this.props.yPos,this.props.zPos)
    if((prevProps.material !== this.props.material) || (prevProps.innerMaterial !== this.props.innerMaterial)) this.setMaterial(this.props.material, this.props.innerMaterial) 
  }
  componentWillUnmount() {
    this.props.scene.remove(this.drawer)
    this.box.geometry.dispose()
    this.front.geometry.dispose()
  }
 
  render() {
    return (null)
  }

}