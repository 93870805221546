import React, { useEffect, useRef, useCallback } from 'react';
import Board from './Board'
import { blueprint } from '../datamodels';

function Selector (props) {

    // const tmp = useStore(state => state.confi)
    const cubeRef = useRef()
    const isActive = useRef(false)
    const material = useRef(props.material.clone())
    const callback = props.callback

    const onMouseOver = () => {
        isActive.current = true
        cubeRef.current.material.opacity = 0.3
    }
    const onMouseOut = () => {
        isActive.current = false
        cubeRef.current.material.opacity = 0.1
    }
    //useMemo to prevent rerendring by function prop
    const onMouseClicked = useCallback(() => {
        if(isActive.current) callback()
    },[callback])
    
    useEffect(() => {
        cubeRef.current.onMouseOver = onMouseOver
        cubeRef.current.onMouseOut = onMouseOut
        cubeRef.current.onMouseClicked = onMouseClicked
        cubeRef.current.material.opacity = 0.1
    },[cubeRef,onMouseClicked])
    
    return (
        <RefBoard ref={cubeRef} {...props} material={material.current} /> // wow, new props need to be set AFTER props are spread to not be overwritten? wtf
    )
}
// RefBoard uses reference Forwarding to get a reference to the THREE cube object for manipulation
const RefBoard = React.forwardRef((props,ref) => <Board parentRef={ref} {...props} />)

function VSelector(props) {
    const cols = props.cols
    const index = props.index
    let nextboard
    let previousboard
        for (let j = index - 1; j >= -1; j--){
            previousboard = j + 1
            j = j<0 ? -2 : cols[j].visible ? -2 : j
            }
        for (let x = index + 1; x <= cols.length; x++){
                nextboard = x + 1
                x = x + 1 > cols.length ? cols.length + 2 : cols[x].visible ? cols.length + 2 : x
            }
        const visible = (nextboard - previousboard) * props.colSize > blueprint.maxSpan && cols[index].visible ? false : true
    return <Selector {...props} visible={visible} rotation={{x:0,y:0,z:0.5}} translate={{x:0.5,y:-0.5,z:0.5}} />
}
//Just a wrapper
function HSelector(props) {
    return <Selector {...props} />
}

export {Selector, VSelector, HSelector}