import React from 'react';
import Price from '../footer/price/price';
import { blueprint } from '../../datamodels';
import { useStore } from '../../store/store';


export function InfoBoxPrint() {

  const width = useStore(store => store.config.width)
  const depth = useStore(store => store.config.depth)
  const material = useStore(store => store.config.material)
  const treatment = useStore(store => store.config.treatment)
  const boards = useStore(store => store.config.boards.list)
  const tabelars = useStore(store => store.config.tabelars.list)
  const backplanes = useStore(store => store.config.backplanes)
  const drawers = useStore(store => store.config.drawers)
  const doors = useStore(store => store.config.doors)
  const grid = useStore(store => store.config.grid)
  const drawer_material_price = drawers.material.price
  const feet = useStore(store => store.config.feet)
  const wallMounted = useStore(store => store.config.wallMounted)
  const localFactor = useStore(store => store.config.localFactor)

  const footHeight = feet.height < 4 ? blueprint.footHeights[feet.height] : 0
  const treatmentName = material.treatment ? (treatment.name) : "-"
  const fullHeight = Math.round((boards.reduce((pv, cv) => pv + cv.height, 0) + blueprint.thickness + footHeight) * 1000)
  const drawerFrontMaterial = drawer_material_price !== 'ref' ? drawers.material.name : material.name
  const fussWand = (wallMounted === 0) ? "stellfuss höhe " + blueprint.footHeights[feet.height]*1000+ " mm (" + (blueprint.footHeights[feet.height]*1000-4) + "-" + (blueprint.footHeights[feet.height]*1000+4) + " mm)" : "wandmontiert"

  let totalCols = 0
  let totalTabelars = 0
  let totalBackplanes = 0
  let totalDrawers = 0
  let totalInnerDoors = 0
  let totalOuterDoors = 0

  for (let i = 0; i < boards.length; i++) {
    const board = boards[i]
    const cols = boards[i].cols
    const backplane = backplanes.list[i]
    for(let j = 0; j < cols.length; j++) {
      const col = cols[j]
      const tabelar = tabelars[i][j] || 0
      if(board.height > 0.3 && tabelar[0] === 1) {totalTabelars++}
      if(board.height > 0.5 && tabelar[1] === 1) {totalTabelars++}
      if(board.height > 0.5 && tabelar[2] === 1) {totalTabelars++}
      if(board.height > 0.7 && tabelar[3] === 1) {totalTabelars++}
      if(board.height > 0.7 && tabelar[4] === 1) {totalTabelars++}
      if(board.height > 0.7 && tabelar[5] === 1) {totalTabelars++}
      if(col.visible) {
        totalCols++
      }
    }
    for (let x = 0; x <= board.cols.length; x++) {
      const drawer = drawers.list[i][x] || 0
      const wall = board.cols[x] || {visible:true}
      const nextWall = board.cols[x + 1] || {visible:true}
      const multiplier = wall.visible ? 1 : nextWall.visible ? 2 : 3 
      if (drawer.height > 0 && drawer.number !== 0) {       
        totalDrawers++
      }
      x += multiplier - 1
    }
    if(backplane.full) {
      totalBackplanes++
    } else {
      for(let x = 0; x < grid; x++) {
        const wall = board.cols[x] || {visible:true}
        const nextWall = board.cols[x + 1] || {visible:true}
        const multiplier = wall.visible ? 1 : nextWall.visible ? 2 : 3 
        if(backplane.cover[x]) totalBackplanes++
        x += multiplier - 1
      }
    }
    for (let d = 0; d < doors.list.length; d++) {
      if(doors.list[d].type === 1) totalOuterDoors ++
      if(doors.list[d].type === 0) totalInnerDoors ++
    }
    totalCols += 2
  }


  return (
    <div className="description">
    <div className="print_title">Analog</div>
    <div className="container">
      <div className="col">
      <div className="sub_title">Material</div>
          {material.name}
      <div className="sub_title">Oberfläche</div>
          {treatmentName}
      <div className="sub_title">Abmessungen</div>
          {width*1000} mm x {depth*1000} mm x {fullHeight} mm
      <div className="sub_title">lichte Etagenhöhen</div>
      {boards.map((board, i) => {
                const height = (board.height - blueprint.thickness) * 1000
                if ( (i + 1) < boards.length){
                  return (<span key={i}>{height} mm, </span>)
                }
                  else{
                    return (<span key={i}>{height} mm</span>)
                  }                  
            })}
      <div className="sub_title">Fuss / Wandmontage</div>
      {fussWand}
        </div>
        <div className="col">
          <div className="sub_title">Regal</div>
          <div>{boards.length + 1} Regalböden</div>
          <div>{totalCols} Seiten</div>
          <div>{totalBackplanes} Rückwände, {backplanes.material.name}</div>
          <div>{totalTabelars} Fachböden</div>
          <div className="sub_title">Ausstattung</div>              
          <div>{totalDrawers} Schubläden, Front {drawerFrontMaterial}</div>
          <div>{totalInnerDoors} Schiebetüren innenliegend, {doors.material.name}</div>
          <div>{totalOuterDoors} Schiebetüren vorlaufend, {doors.material.name}</div>
          <div className="price_title">Preis {localFactor.name}</div>
          <div className="price"><Price /> €</div>
          <div className="left">ohne Lieferung / Montage</div><div className="right">{localFactor.vat}</div>
        </div></div>
    </div>
  )
}