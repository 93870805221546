import * as THREE from 'three'
import React, { useRef, useLayoutEffect } from 'react';


export function PointLight({scene,color=0xffffff,intensity,distance,decay,position,name=""}) {

  const light = useRef(new THREE.PointLight(color,intensity,distance))

  useLayoutEffect(function init() {
    const tmpLight = light.current
    tmpLight.name = name
    scene.add(tmpLight)
    return () => {
      scene.remove(tmpLight)
    }
  },[scene, name])

  useLayoutEffect(() => {
    light.current.position.set(position.x, position.y, position.z)
  }, [position])

  return null
}



export function DirectionalLight({ scene, color=0xffffff, strength, position, castShadow=true,name=""}) {

  const light = useRef(new THREE.DirectionalLight(color, strength))

  useLayoutEffect(function init() {
    const tmpLight = light.current
    tmpLight.castShadow = castShadow
    tmpLight.shadow.mapSize.set(512, 512);
    tmpLight.shadow.camera.near = 0.001
    tmpLight.shadow.camera.far = 50
    tmpLight.shadow.radius = 2
    tmpLight.name = name
    scene.add(tmpLight)
    console.log("light created")
    return () => {
      scene.remove(tmpLight)
    }
  }, [scene, castShadow, name])

  useLayoutEffect(function setPosition() {
    light.current.position.set(position.x, position.y, position.z)
  }, [position])

  return null
}

export function SpotLight({ scene, color=0xffffff, strength, position, castShadow=false,name=""}) {

  const light = useRef(new THREE.SpotLight(color, strength))

  useLayoutEffect(function init() {
    const tmpLight = light.current
    tmpLight.castShadow = castShadow
    tmpLight.shadow.mapSize.set(512, 512);
    tmpLight.shadow.camera.near = 0.001
    tmpLight.shadow.camera.far = 50
    tmpLight.shadow.radius = 2
    tmpLight.name = name
    scene.add(tmpLight)
    console.log("Spotlight created")
    return () => {
      scene.remove(tmpLight)
    }
  }, [scene, castShadow, name])

  useLayoutEffect(function setPosition() {
    light.current.position.set(position.x, position.y, position.z)
  }, [position])

  return null
}

export function AmbientLight({scene,color=0xffffff,intensity,name=""}) {
  const light = useRef(new THREE.AmbientLight(color, intensity))
  useLayoutEffect(() => {
    const l = light.current
    scene.add(l)
    return(() => scene.remove(l))
  },[scene])
  
  return null
}

export function LightGroup({scene}) {

  const lightGroup = useRef(new THREE.Group())

  useLayoutEffect(function init() {
    const lGroup = lightGroup.current
    scene.add(lGroup)
    return (() => scene.remove(lGroup))
  },[scene])

  return(
    <>
      <PointLight scene={lightGroup.current} intensity={1} distance={5} position={{x:2, y:2, z:1}} name="right pointlight" />
      <PointLight scene={lightGroup.current} intensity={1} distance={5} position={{x:-2, y:2, z:1}} name="left pointlight" />
      <PointLight scene={lightGroup.current} intensity={0.5} distance={5} position={{x:2, y:2, z:-1}} name="right rear pointlight" />      
      <PointLight scene={lightGroup.current} intensity={0.5} distance={5} position={{x:-2, y:2, z:-1}} name="left rear pointlight" />
      <AmbientLight scene={lightGroup.current} intensity={1.4} name="ambient light" />
      {/* <DirectionalLight scene={lightGroup.current} intensity={0.4} position={{x:1, y:4, z:0.2}} /> */}
      {/* <DirectionalLight scene={lightGroup.current} intensity={0.9} position={{x:-1, y:4, z:0.3}} /> */}
      {/* <SpotLight scene={lightGroup.current} intensity={0.3} position={{x:0, y:5, z:0.2}} name="top spot" /> */}
     </>
  )
}