import React, { useCallback } from 'react'
import DoorSelector from './doorSelector'
import { blueprint } from '../../../datamodels'
import { useStore } from '../../../store/store'


export default function DoorSelectors({boards, material, d1x1Group,d2x1Group,d3x1Group,d4x1Group,d1x2Group,d2x2Group,d3x2Group,d4x2Group}) {
  let totalHeight = 0
  const width = useStore(store => store.config.width)
  const grid = useStore(store => store.config.grid)
  const editDoorGroup = useStore(state => state.config.doors.edit)
  //make new editDoorGroup state for 4 door variants, make new three-groups here and simply show hide depending on edit state
  const colSize = (width - blueprint.thickness * 2 + blueprint.shadowJoint) / grid
  const door4bSize = (colSize * 4 - blueprint.shadowJoint)
  const door3bSize = (colSize * 3 - blueprint.shadowJoint)
  const door2bSize = (colSize * 2 - blueprint.shadowJoint)
  const door1bSize = (colSize * 1 - blueprint.shadowJoint)
  d1x1Group.visible = editDoorGroup === 1
  d2x1Group.visible = editDoorGroup === 2
  d3x1Group.visible = editDoorGroup === 3
  d4x1Group.visible = editDoorGroup === 4
  d1x2Group.visible = editDoorGroup === 5
  d2x2Group.visible = editDoorGroup === 6
  d3x2Group.visible = editDoorGroup === 7
  d4x2Group.visible = editDoorGroup === 8

  return(
      boards.map((board, i) => {
          totalHeight += board.height
          const nextBoard = boards[i + 1] || {height:0}
          const nextHeight = nextBoard.height
          return (
              <React.Fragment key={i}>
                  <SelectorRow scene={d1x1Group} row={i} length={door1bSize} rowHeight={board.height} elementHeight={board.height} totalHeight={totalHeight} material={material} selectorWidth={1} selectorHeight={1} />
                  <SelectorRow scene={d2x1Group} row={i} length={door2bSize} rowHeight={board.height} elementHeight={board.height} totalHeight={totalHeight} material={material} selectorWidth={2} selectorHeight={1} />
                  <SelectorRow scene={d3x1Group} row={i} length={door3bSize} rowHeight={board.height} elementHeight={board.height} totalHeight={totalHeight} material={material} selectorWidth={3} selectorHeight={1} />
                  <SelectorRow scene={d4x1Group} row={i} length={door4bSize} rowHeight={board.height} elementHeight={board.height} totalHeight={totalHeight} material={material} selectorWidth={4} selectorHeight={1} />
                  <SelectorRow scene={d1x2Group} row={i} length={door1bSize} rowHeight={board.height} elementHeight={board.height + nextHeight} totalHeight={totalHeight} material={material} selectorWidth={1} selectorHeight={2} />
                  <SelectorRow scene={d2x2Group} row={i} length={door2bSize} rowHeight={board.height} elementHeight={board.height + nextHeight} totalHeight={totalHeight} material={material} selectorWidth={2} selectorHeight={2} />
                  <SelectorRow scene={d3x2Group} row={i} length={door3bSize} rowHeight={board.height} elementHeight={board.height + nextHeight} totalHeight={totalHeight} material={material} selectorWidth={3} selectorHeight={2} />
                  <SelectorRow scene={d4x2Group} row={i} length={door4bSize} rowHeight={board.height} elementHeight={board.height + nextHeight} totalHeight={totalHeight} material={material} selectorWidth={4} selectorHeight={2} />
              </React.Fragment>
          )
      })
  )
}

function SelectorRow({scene, row, length, selectorWidth, selectorHeight, rowHeight, elementHeight, totalHeight, material}) {
  const width = useStore(store => store.config.width)
  const grid = useStore(store => store.config.grid)
  const depth = useStore(store => store.config.depth)
  const numOfDoors = Math.floor(grid - selectorWidth + 1)
  const positiveDoors = numOfDoors > 0 ? numOfDoors : 0
  const colSize = (width - 2 * blueprint.thickness) / grid
  const setDoorAt = useStore(useCallback(store => store.doors.clickedAt,[]))

  const doorClicked = (doorRow, doorCol, doorWidth, doorHeight) => {
    setDoorAt(doorWidth,doorHeight,{x:doorCol,y:doorRow})
  }

  return (
      [...Array(positiveDoors)].map((_,i) => 
          <DoorSelector 
              key={i} 
              scene={scene} 
              callback={() => doorClicked(row, i, selectorWidth, selectorHeight)} 
              length={length} 
              depth={depth} 
              height={elementHeight - blueprint.thickness} 
              visible={true} 
              material={material} 
              innerMaterial={material} 
              xPos={i * colSize + blueprint.thickness}
              yPos={totalHeight - rowHeight + blueprint.thickness} 
              boardThickness={blueprint.thickness} 
          />
      )
  )
}