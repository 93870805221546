/** 
 * These are static models (they dont change) so can be imported everywhere needed -> no need to pass down and save in state 
 */
export const blueprint = {
  gridSize: 0.364,  
  minWidth: 0.300,
  gridMin: 0.273,
  gridMax: 0.546,  
  minDepth: 0.200,
  maxDepth: 0.600,
  minColHeight: 0.15,
  maxColHeight: 0.9,
  thickness: 0.019,
  shadowJoint: 0.003,
  depth: [0.33, 0.41],
  width: [0.383, 0.747, 1.111, 1.475, 1.839, 2.203, 2.567, 2.931],
  colHeight: [0.273, 0.364, 0.546, 0.728],
  footHeights: [0.023, 0.105, 0.155, 0.205, 0.5],
  col: { visible: true },
  maxSpan: 1.1
}

export const API_DOMAIN = "https://api.sanktjohanser.net"

//Export Material Objects for all Three Elements that have a material state -> shelf, doors, drawers, back 
const shelfMaterialModel = [
  {id:0, matReference:0, price:45, maxWidth:3.58, fallback:10, drawerInnerMaterial:10, treatment:true, type:0, name:"multiplex ahorn"},
  {id:1, matReference:1, price:45, maxWidth:3.58, fallback:11, drawerInnerMaterial:11, treatment:true, type:0, name:"multiplex birke"},
  {id:2, matReference:2, price:45, maxWidth:3.58, fallback:12, drawerInnerMaterial:12, treatment:true, type:0, name:"multiplex buche"},
  {id:3, matReference:3, price:45, maxWidth:3.58, fallback:13, drawerInnerMaterial:13, treatment:true, type:0, name:"multiplex eiche"},
  {id:4, matReference:4, price:50, maxWidth:3.58, fallback:14, drawerInnerMaterial:14, treatment:true, type:0, name:"multiplex kirschbaum"},
  {id:5, matReference:5, price:50, maxWidth:3.58, fallback:15, drawerInnerMaterial:15, treatment:true, type:0, name:"multiplex amerik. nussbaum"},
  {id:6, matReference:6, price:30, maxWidth:2.98, fallback:13, drawerInnerMaterial:"solidWhite", treatment:false, type:0, name:"multiplex melamin weiss"},
  {id:7, matReference:7, price:80, maxWidth:2.98, fallback:13, drawerInnerMaterial:"solidWhite", treatment:false, type:0, name:"multiplex hpl softtouch weiss"},
  {id:8, matReference:8, price:40, maxWidth:2.98, fallback:13, drawerInnerMaterial:"solidBlack", treatment:false, type:0, name:"multiplex cpl schwarz"},
  {id:9, matReference:9, price:80, maxWidth:2.98, fallback:13, drawerInnerMaterial:"solidBlack", treatment:false, type:0, name:"multiplex hpl softtouch schwarz"},
  {id:10, matReference:10, price:95, maxWidth:3.50, fallback:0, drawerInnerMaterial:10, treatment:true, type:1, name:"ahorn massiv"},
  {id:11, matReference:11, price:65, maxWidth:3.50, fallback:1, drawerInnerMaterial:11, treatment:true, type:1, name:"birke massiv"},
  {id:12, matReference:12, price:65, maxWidth:3.50, fallback:2, drawerInnerMaterial:12, treatment:true, type:1, name:"buche massiv"},
  {id:13, matReference:13, price:95, maxWidth:3.50, fallback:3, drawerInnerMaterial:13, treatment:true, type:1, name:"eiche massiv"},
  {id:14, matReference:14, price:135, maxWidth:3.00, fallback:4, drawerInnerMaterial:14, treatment:true, type:1, name:"kirschbaum massiv"},
  {id:15, matReference:15, price:180, maxWidth:3.00, fallback:5, drawerInnerMaterial:15, treatment:true, type:1, name:"nussbaum amerikanisch massiv"},
  {id:16, matReference:16, price:225, maxWidth:3.00, fallback:5, drawerInnerMaterial:15, treatment:true, type:1, name:"nussbaum europäisch massiv"},
  {id:17, matReference:17, price:250, maxWidth:3.00, fallback:5, drawerInnerMaterial:15, treatment:true, type:1, name:"schwarznuss massiv"},
]

export const shelfModel = [
  {
    key: 1,
    height: 0.364,
    cols: [ { visible: false }, { visible: true }, { visible: false }, { visible: true }, { visible: false }]
  },
  {
    key: 2,
    height: 0.364,
    cols: [ { visible: false }, { visible: true }, { visible: true }, { visible: false }, { visible: false }]
  }
]

const doorMaterialModel = [
  {id:0, price:44, matReference:'hplWhite', innerMatReference:'solidAlu', shelfReference:[], name:"Hpl weiss"},
  {id:1, price:44, matReference:'hplBlack', innerMatReference:'solidAlu', shelfReference:[], name:"Hpl schwarz"},
  {id:2, price:50, matReference:'hplAlu', innerMatReference:'solidAlu', shelfReference:[], name:"Hpl aluminium"},
  {id:3, price:70, matReference:'hplOak', innerMatReference:'solidAlu', treatment:true, shelfReference:[3,13], name:"Hpl eiche funiert"},
  {id:4, price:70, matReference:'hplNut', innerMatReference:'solidAlu', treatment:true, shelfReference:[5,15], name:"Hpl nuss funiert"}
] 

const doorModel = [{pos:{x:0,y:1}, size:{x:1,y:1}, type:1}]

const drawerMaterialModel = [
  {id:0, price:44, matReference:'hplWhite', shelfReference:[], name:"Hpl weiss"},
  {id:1, price:44, matReference:'hplBlack', shelfReference:[], name:"Hpl schwarz"},
  {id:2, price:50, matReference:'hplAlu', shelfReference:[], name:"Hpl Aluminium"},
  {id:3, price:'ref', matReference:'ref', treatment:true, shelfReference:[], name:"Holz"},
]

//TODO change number to quantity for clearety
const drawerModel = [
  [
    {height:1, number:0},
    {height:1, number:0},
    {height:1, number:0},
    {height:1, number:0},
    {height:1, number:0},
    {height:1, number:0}
  ],
  [
    {height:1, number:0},
    {height:2, number:0},
    {height:1, number:0},
    {height:2, number:1},
    {height:1, number:0},
    {height:2, number:0}
  ],
  [
    {height:1, number:0},
    {height:1, number:0},
    {height:1, number:0},
    {height:1, number:0},
    {height:1, number:0},
    {height:1, number:0}
  ],
]

const backplaneMaterialModel = [
  {id:0, price:10, matReference:'hplWhite', shelfReference:[], name:"Mdf weiss"},
  {id:1, price:40, matReference:'hplBlack', shelfReference:[], name:"Hpl schwarz"},
  {id:2, price:65, matReference:'ref', treatment:true, shelfReference:[0,10], name:"Ahorn funiert"},
  {id:3, price:65, matReference:'ref', treatment:true, shelfReference:[1,11], name:"Birke funiert"},
  {id:4, price:65, matReference:'ref', treatment:true, shelfReference:[2,12], name:"Buche funiert"},
  {id:5, price:65, matReference:'ref', treatment:true, shelfReference:[3,13], name:"Eiche funiert"},
  {id:6, price:75, matReference:'ref', treatment:true, shelfReference:[4,14], name:"Kirschbaum funiert"},
  {id:7, price:75, matReference:'ref', treatment:true, shelfReference:[5,15], name:"Nussbaum funiert"},
] 

const backplaneModel = [
  {full:false,cover:[0,0,0,0]},
  {full:false,cover:[0,0,0,1,0]},
  {full:false,cover:[0,0,0]}
]

const tabelarModel = [
  [
    [0,0,0,0,1,0],
    [0,0,0,0,1,0],
    [0,0,0,0,1,0],
    [0,0,0,0,1,0],
    [0,0,0,0,1,0],
    [0,0,0,0,1,0],
  ],
  [
    [0,0,0,0,1,0],
    [0,0,0,0,1,0],
    [0,0,0,0,1,0],
    [0,0,0,0,1,0],
    [0,0,0,0,1,0],
    [0,0,0,0,1,0],
  ],
  [
    [1,0,1,0,1,0],
    [1,0,0,0,1,0],
    [0,0,0,0,1,0],
    [0,0,0,0,1,0],
    [0,0,0,0,1,0],
    [0,0,0,0,1,0],
  ]
]

// price lookup much simpler, no drilling
const woodTreatments = [
  {id:0,price:25,setup:0,name:"geölt"},
  {id:1,price:25,setup:0,name:"hell geölt"},
  {id:2,price:32.5,setup:10,name:"geseift"},
  {id:3,price:35,setup:30,name:"lackiert"},
]

const localFactors = [
  {id:0, factor:1.0, name:"DE", fullname:"Deutschland", vat:"netto"},
  {id:1, factor:1.19, name:"DE", fullname:"Deutschland", vat:"inkl. 19% Mwst"},
  {id:2, factor:1.0, name:"CH", fullname:"Schweiz", vat:"netto"},
  {id:3, factor:1.0, name:"AT", fullname:"Österreich", vat:"netto"},
  {id:4, factor:1.0, name:"IT", fullname:"Italien", vat:"netto"},
  {id:5, factor:1.1, name:"EXPORT", fullname:"Export", vat:"netto"}
]

const workPrices = {
  "Faktor":3.3,
  "Oelen":25,
  "Kante":1.5,
  "Lohn":60,
  "Verschnitt":1.2,
  "Handling":3.5,
  "Zuschnitt":3.5,
  "CNC":100,
  "Werkstueckwechsel":120,
  "Setup":15,
  "Werkzeugwechsel":25,
  "Bohrloch":1.5,
  "Formatieren":12,
  "Fasen":12,
  "Schleifen":25,
  "Nuten":4,
  "Beschlag":0.4,
  "AluZ":6,
  "AluFlach":4,
  "Klebeband":0.8,
  "ZeitSTVL":20,
  "ZeitIL":8,
  "HoherFuss":12.12,
  "BeschlagSK":25,
  "ZeitRW":8,
  "ZeitSK":0,
  "WandRegalBoden":12,
  "WandRegalSeite":6
}

const shelfDescription = "modulares regal- und sideboardsystem. in massivholz oder multiplex. analog wird geschossweise aufgebaut. vier verschiedene etagenhöhen können frei kombiniert werden. die drei grösseren fachhöhen sind zusätzlich mit verstellbaren fachböden ausgestattet. leichte schiebetüren in eiche furniert, mattweiss, mattschwarz(anti-finger-print) oder aluminium laufen vor oder in dem korpus. verschiedene schiebetürgrössen können beliebig angeordnet werden und lassen so die unverwechselbare ästhetik von analog entstehen. innenliegende schiebetüren ermöglichen ein komplett geschlossenes möbel."

export {drawerModel, backplaneModel, tabelarModel, doorMaterialModel, drawerMaterialModel, backplaneMaterialModel, shelfMaterialModel, woodTreatments, shelfDescription, localFactors, doorModel, workPrices as w}