import React from 'react';
import * as THREE from 'three'
import * as OrbitControls from './OrbitControls'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass'
import AppContext from './../Context'
import Stats from 'stats-js'
import Analog from './Analog';
import { LightGroup } from './lights';
import { useStore } from '../store/store';

// const TWEEN = require('@tweenjs/tween.js')

class ThreeScene extends React.Component {
    static contextType = AppContext

    constructor(props) {
        super(props)
        this.stats = null
        this.selectedRow = null
        this.ref = React.createRef()
        this.currentPerspective = this.getViews()[this.props.perspective]
        this.scene = new THREE.Scene();
        this.renderer = this.initRenderer()
        this.raycaster = this.initRaycaster()
        this.resetView = this.resetView.bind(this)

        const width = document.innerWidth - 300
        const height = document.innerHeight
        this.camera = this.initCamera(width, height)
        this.scene.add(this.camera)
        // for THREE INSPECT
        window.scene = this.scene
        window.THREE = THREE
        // INSPECT END
        // window.addEventListener('load', ()=>console.log("Custom Loader"))
        // window.addEventListener('load', ()=>this.animate())
        // THREE.DefaultLoadingManager.onStart = () => console.log("RENDER START")
    }

    getViews(view) {                
        const views = [
            {
                position: { x: 0, y: 1.25, z: 8 },
                lookAt: { x: 0, y: 1.25, z: 0 },
                offset: { x: 0, y: 150 },
                rot: { x: 0, y: 0 }
            },
            {
                //position: { x: -4, y: 1.7, z: 3 },
                //lookAt: { x: 0.1, y: 1.7, z: 0 },
                //offset: { x: 0, y: 0 },
                rot: { x: 30, y: 0 }
            },
            {
                //position: { x: 4, y: 1, z: 3 },
                //lookAt: { x: 0.1, y: 1, z: 0 },
                //offset: { x: 0, y: 0 },
                rot: { x: 90, y: 0 }
            },            
            {   //view from back -> for print
                position: { x: 0, y: 1.25, z: -8 },
                lookAt: { x: 0, y: 1.25, z: 0 },
                offset: { x: 0, y: 0 },
                rot: { x: 180, y: 0 }
            }
        ]
        return views
    }

    componentDidMount() {
        
        this.controls = this.initOrbitControls(this.camera, this.renderer.domElement)
        // this.controls.enableZoom = true
        // this.controls.enablePan = true
        // this.controls.minPolarAngle = Math.PI / 2
        this.controls.maxPolarAngle = Math.PI / 2
        this.initView()
        this.fitToView()
        // this.controls.saveState()

        this.stats = this.getStats()

        this.ref.current.appendChild(this.renderer.domElement);
        //this.ref.current.appendChild(this.stats.dom) //only for dev

        this.composer = this.getPostprocessingComposer()
        // this.scene.add(this.getWallPlane())
        this.scene.add(this.getFloor())
        // this.camera.add(this.getLightGroup()) //added to cam to be static to cam


        // added here cause the ref is here; could pass it down to shelf to handle raycasting there - lets see
        this.calculateRaycast = (e) => {
            let boundRect = this.ref.current.getBoundingClientRect();
            const tX = e.clientX - boundRect.left
            const tY = e.clientY - boundRect.top

            this.raycaster.mouse.x = (tX / boundRect.width) * 2 - 1;
            this.raycaster.mouse.y = - (tY / boundRect.height) * 2 + 1;
        }
        //this.ref.current.parentElement.parentElement.parentElement.parentElement.addEventListener('mousemove', this.calculateRaycast, false)
        document.getElementById("root").addEventListener('mousemove', this.calculateRaycast, false)

        window.addEventListener('resize', this.onWindowResize)
        window.addEventListener('mouseup', this.resetView)
        // this.animateWithPostprocessing();
        // this.animate()
        // this.ref.current.addEventListener('load', ()=>this.animate())
        THREE.DefaultLoadingManager.onLoad = () => {
            this.animate()
            this.getScreenCoordinates()
        }
        this.renderer.shadowMap.needsUpdate = true;
    }

    componentWillUnmount() {
        document.getElementById("root").removeEventListener('mousemove', this.calculateRaycast)
        window.removeEventListener('resize', this.onWindowResize)
        window.removeEventListener('mouseup', this.resetView)
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.perspective !== prevProps.perspective) {
            this.currentPerspective = this.getViews()[this.props.perspective]
            this.setOrbitPosition(this.currentPerspective.rot.x, this.currentPerspective.rot.y)
        }
        this.fitToView()
        if((this.props.boards !== prevProps.boards) || (this.props.editStructure !== prevProps.editStructure)) this.getScreenCoordinates()
        // this.renderer.shadowMap.needsUpdate = true;
    }

    getStats = () => {
        let stats = new Stats();
        stats.showPanel(0);
        return (stats);
    }

    getScreenCoordinates = () => {
        // console.log(group.children)
        let totalHeight = this.props.footHeight
        if (this.props.boards) {
            const newPos = this.props.boards.map((board,i) => {
                const p = new THREE.Vector3(0,totalHeight + board.height/2,0)
                p.project(this.camera)
                // convert the normalized position to CSS coordinates
                // const x = (p.x *  .5 + .5) * this.ref.current.clientWidth;
                const y = (p.y * -.5 + .5) * this.ref.current.clientHeight;
                totalHeight += board.height
                return y
            })
        newPos.reverse()
        this.props.setBoardToScreenPositions(newPos)
        }
    }

    fitToView(offset) {
        let threeObject = this.props.editStructure ? this.scene.getObjectByName("fullGroup") : this.scene.getObjectByName("shelfGroup")
        const boundingBox = new THREE.Box3()
        let center = new THREE.Vector3(0, 0, 0)
        let size = new THREE.Vector3()
        boundingBox.setFromObject(threeObject)
        boundingBox.getCenter(center)
        boundingBox.getSize(size)
        // const maxSize = Math.max(size.x * 1.2, (size.y + 0) * 1.8)
        let maxSize
        let aspect
        let distance = this.getViews()[0].position.z - this.getViews()[0].lookAt.z        
        
        if (this.props.print){
            maxSize = Math.max(size.x * 0.85, size.y * 1.1)            
            maxSize = (maxSize >= 1.5) ? maxSize : 1.5
            aspect = (this.ref.current.clientWidth) / (this.ref.current.clientHeight - 280)
        }
        else{
            maxSize = Math.max(size.x, size.y * 1.35)
            maxSize = (maxSize >= 3) ? maxSize : 3
            aspect = (this.ref.current.clientWidth - 300) / (this.ref.current.clientHeight - 170)
        }
        
        let fovy = 2 * Math.atan((maxSize) / (2 * distance)) * (180 / Math.PI)
        let fovx = 2 * Math.atan((size.x / aspect) / (2 * distance)) * (180 / Math.PI)
        let fov = Math.max(fovx, fovy)

        this.camera.fov = fov

        let off = this.getViews()[0].offset.y - ( 1 - 1 / (2 * distance * Math.tan(fov / (360 / Math.PI)) / (2 * this.getViews()[0].position.y))) * this.ref.current.clientHeight / 2
        off = this.props.print ? off - 110 : off
        this.camera.setViewOffset(this.ref.current.clientWidth, this.ref.current.clientHeight, 0, off, this.ref.current.clientWidth, this.ref.current.clientHeight)
        this.camera.updateProjectionMatrix();
        
        // this.controls.update()
    }

    degreeToRadians(degree) {
        return degree * (Math.PI / 180)
    }

    setOrbitPosition(xDegree, yDegree, fixed = true) {
        const xRadians = this.degreeToRadians(xDegree)
        const yRadians = this.degreeToRadians(yDegree)
        const currentOrbit = { x: this.controls.getAzimuthalAngle(), y: this.controls.getPolarAngle() }
        const deltaOrbit = { x: currentOrbit.x - 0 + xRadians, y: currentOrbit.y - 1.5582969777755349 + yRadians }
        this.controls.rotateLeft(deltaOrbit.x)
        this.controls.rotateUp(deltaOrbit.y)
    }

    initView() {
        const view = this.currentPerspective
        const width = this.ref.current.clientWidth
        const height = this.ref.current.clientHeight
        this.setRenderSize(width, height) // fix resolution
        this.camera.position.set(view.position.x, view.position.y, view.position.z)
        this.camera.setViewOffset(width, height, view.offset.x, view.offset.y, width, height)
        this.controls.target.set(view.lookAt.x, view.lookAt.y, view.lookAt.z)
        // this.camera.updateProjectionMatrix()
    }
    resetView() {
        //TODO write smooth reset view
        this.setOrbitPosition(this.currentPerspective.rot.x, this.currentPerspective.rot.y)
        // this.controls.reset()
    }
    // setViewOffset

    fixCameraRatio() {
        const width = this.ref.current.clientWidth
        const height = this.ref.current.clientHeight
        this.camera.aspect = width / height // fix distortion
    }
    // do we need this as a function?
    setRenderSize(width, height) {
        this.renderer.setSize(width, height, false) // fix resolution
    }

    onWindowResize = () => {
        // const view = this.currentPerspective
        const width = this.ref.current.clientWidth
        const height = this.ref.current.clientHeight
        this.fixCameraRatio(width, height)
        this.camera.updateProjectionMatrix()
        this.getScreenCoordinates()
        this.fitToView()
    }

    initCamera = (width, height) => {
        let camera = new THREE.PerspectiveCamera(35, width / height, 0.1, 1000);
        return camera
    }

    initRenderer = () => {
        let renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
        // renderer.preserveDrawingBuffer = this.props.print || false
        renderer.gammaFactor = 2.2;
        renderer.gammaOutput = true;
        renderer.physicallyCorrectLights = true
        renderer.shadowMap.enabled = true
        // renderer.shadowMap.type = THREE.PCFSoftShadowMap
        renderer.shadowMap.autoUpdate = true
        const pRatio = window.devicePixelRatio > 1.5 ? window.devicePixelRatio : 1.5
        renderer.setPixelRatio(pRatio);
        return renderer
    }

    initOrbitControls = (cam, renderEl) => {
        let controls = new OrbitControls(cam, renderEl)
        controls.enableDamping = true
        controls.dampingFactor = 0.1
        controls.enableZoom = false
        controls.enablePan = false
        // controls = this.setPanning(controls)
        // controls.maxPolarAngle = Math.PI / 2.02
        return controls
    }

    setPanning = (c) => {
        c.minPolarAngle = Math.PI / 2.1
        c.maxPolarAngle = Math.PI / 2.1
        c.maxAzimuthAngle = 0
        c.minAzimuthAngle = 0
        return c
    }

    getWallPlane = () => {
        let geometry = new THREE.PlaneGeometry(4, 4, 1, 1)
        let material = new THREE.ShadowMaterial()
        material.opacity = 0.1
        let wall = new THREE.Mesh(geometry, material)
        material.side = THREE.DoubleSide
        wall.receiveShadow = true
        return wall
    }
    getFloor = () => {
        let floor = this.getWallPlane()
        floor.position.z = -1
        // floor.position.y = -2
        floor.rotateX(THREE.Math.degToRad(-90))
        return floor
    }

    initRaycaster = () => {
        const raycaster = new THREE.Raycaster()
        raycaster.mouse = ({ x: 0, y: 0 })
        return raycaster
    }

    // onMouseMove = (e) => {
    //     // calculate mouse position in normalized device coordinates
    //     // (-1 to +1) for both components
    //     this.mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
    //     this.mouse.y = - (e.clientY / window.innerHeight) * 2 + 1;
    // }

    getPostprocessingComposer = () => {
        let composer = new EffectComposer(this.renderer)
        let renderPass = new RenderPass(this.scene, this.camera)
        composer.addPass(renderPass)

        let smaaPass = new SMAAPass(window.innerWidth * this.renderer.getPixelRatio(), window.innerHeight * this.renderer.getPixelRatio())
        smaaPass.renderToScreen = true;
        composer.addPass(smaaPass)
        return composer
    }

    raycast = (raycaster) => {
        raycaster.setFromCamera(raycaster.mouse, this.camera)
    }

    animateWithPostprocessing = (time) => {
        if (this.stats) this.stats.begin()
        if (this.raycaster) this.raycast(this.raycaster)
        this.controls.update();
        this.composer.render();
        if (this.stats) this.stats.end()
        this.requestID = window.requestAnimationFrame(this.animateWithPostprocessing);
    }

    animate = () => {
        this.stats.begin()
        if (this.raycaster) this.raycast(this.raycaster)
        this.controls.update()
        this.renderer.render(this.scene, this.camera);
        this.stats.end()
        if(this.props.print) this.renderPrint()
        if (!this.props.print) this.requestID = window.requestAnimationFrame(this.animate);
    }
    renderPrint = () => {
        const imgObj = this.renderer.domElement.toDataURL('image/png', 1.0)
        const imgContainer = document.createElement('div')
        imgContainer.className += 'print_preview'
        const tmpImg = document.createElement('img')
        tmpImg.src = imgObj
        imgContainer.appendChild(tmpImg)
        console.log("appending Image", imgObj)
        console.log(this.renderer.state)
        this.ref.current.appendChild(imgContainer)
    }

    render() {
        return (
            <>
            <div ref={this.ref} style={{height:'100%', width:'100%'}}>
                {/* Canvas will be inserted here */}
            </div >
                <LightGroup scene={this.scene} />
                <Analog
                    doorsVisible={this.props.doorsVisible} //passed down for print view
                    drawersVisible={this.props.drawersVisible} // passed down for print view
                    scene={this.scene}
                    renderer={this.renderer}
                    raycaster={this.raycaster}
                    camera={this.camera}
                    innerRef={this.ref}
                />
            </>
        )
    }
}
export default ThreeScene


export const ConnectedScene = () => {

    const footHeight = useStore(store => store.config.feet.height)
    const boards = useStore(store => store.config.boards.list)
    const editStructure = useStore(store => store.config.boards.edit)
    const doorsVisible = useStore(store => store.config.doors.visible)
    const drawersVisible = useStore(store => store.config.drawers.visible)
    const camPerspective = useStore(store => store.config.perspective)

    const setBoardToScreenPositions = useStore(state => state.setBoardToScreenPositions)

    return (
        <ThreeScene
            footHeight={footHeight}
            boards={boards}
            editStructure={editStructure}
            doorsVisible={doorsVisible}
            drawersVisible={drawersVisible}
            perspective={camPerspective}
            setBoardToScreenPositions={setBoardToScreenPositions}
        />
    )
}