import React, { useState, useRef } from 'react';
import {
    Button,
    TextField,
    makeStyles,
    Paper,
    Select,
    FormControl,
    MenuItem,
    ListItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    ListItemIcon
} from '@material-ui/core';
import AnalogStructure from './../../static/analog-3.jpg'
import IosArrowForward from 'react-ionicons/lib/IosArrowForward'
import IosArrowBack from 'react-ionicons/lib/IosArrowBack'
import { Link, useHistory, useParams } from 'react-router-dom'
import { ReactComponent as Fach1 } from "./../../static/1fach.svg"
import { ReactComponent as Fach2 } from "./../../static/2fach.svg"
import { ReactComponent as Fach3 } from "./../../static/3fach.svg"
import { ReactComponent as Fach4 } from "./../../static/4fach.svg"
import { ReactComponent as Fach1x2 } from "./../../static/2x1fach.svg"
import { ReactComponent as Fach2x2 } from "./../../static/2x2fach.svg"
import { ReactComponent as Fach3x2 } from "./../../static/2x3fach.svg"
import { ReactComponent as Fach4x2 } from "./../../static/2x4fach.svg"

const useStyles = makeStyles({
    card: {
        minWidth: 332,
        position: 'absolute',
        right: -16,
        top: 250,
        minHeight: 312,
    },
    header: {
        backgroundSize: '100%, auto',
        backgroundPositionY: '65%',
        //borderBottom: '1px solid lightGray', 
        height: 28,
        textAlign: 'left',
        padding: '0 16px'
    },
    media: {
        height: 160
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)'
    },
    title: {
        fontSize: 14,
        fontWeight: 700,
        textTransform: 'uppercase'
    },
    description: {     
        width: 250,
        textTransform: 'lowercase'
    },
    pos: {
        marginBottom: 12
    },
    formControl: {
        width: 310,
        textAlign: 'left',
        display: 'flex',
        marginTop: 0,
        marginLeft: 10,
        paddingTop: 0,
        textTransform: 'lowercase',
    },
    doorSelectLabel: {
        paddingTop: 8
    },
    MuiListItemIconRoot: {
        minWidth: 35
    },
    selectEmpty: {
        marginTop: 10
    },
    textfield: {
        width: 250,
        marginRight: 5
    },
    listText: {
        width: 250,
        textTransform: 'lowercase'
    },
    dimension: {
        position: 'absolute',
        top: 8,
        right: 15
    },
    slider: {
        maxWidth: 270
    },
    parentButton: {
        textTransform: 'uppercase'
    }
});

export function CardBox(props) {
    const classes = useStyles();
    return (
        <Paper className={classes.card} elevation={0} square>
            {props.children}
        </Paper>
    );
}

export function Header({ title, imgUrl, text, children }) {
    const classes = useStyles();
    return (
        <div className={classes.header} style={imgUrl && { backgroundImage: imgUrl }}>
            <Typography className={classes.title}>{title}</Typography>
            {/* <div className={classes.description}>{text}</div> */}
        </div>
    );
}

export function StructureHeader(props) {
    const imgUrl = 'url(' + AnalogStructure + ')'
    return (
        <Header imgUrl={imgUrl} {...props} />
    )
}

export function ParentButton({ to, children }) {
    const classes = useStyles()

    return (
        <ListItem button component={Link} to={to} >
            <span className={classes.parentButton}>{children}</span>
            <IosArrowForward style={{ position: 'absolute', right: 15, width: 15 }} />
            {/* <ArrowForwardIosIcon style={{ position: 'absolute', right: 15 }} /> */}
        </ListItem>
    );
}

export function MaterialElement({ title, callback, selected, val, units }) {
    const classes = useStyles()

    return (
        // <MaterialListButton title={title} val={val} units={units} to={to} selected={id === idNr} />
        <ListItem button selected={selected} onClick={() => { callback() }}>
            <span className={classes.listText}>{title}</span>
            <span className={classes.dimension}>{val} {units}</span>
        </ListItem>
    )
}

export function ListButton({ title, val, units, to, selected }) {
    selected = selected || false
    const classes = useStyles()
    return (
        <ListItem button component={Link} to={to} selected={selected}>
            <span className={classes.listText}>{title}</span>
            <span className={classes.dimension}>{val} {units}</span>
        </ListItem>
    )
}

export function MaterialSelector({ doorVariant, callback }) {
    const classes = useStyles()
    const s = useRef()

    const handleChangeType = e => {
        const newVal = e.target.value
        callback(newVal)
    }
    return (
        <FormControl className={classes.formControl} ref={s}>
            {/*<InputLabel className={classes.doorSelectLabel} id="door-select-helper-label">Schiebetür Variante</InputLabel>*/}
            <Select
                labelId="door-select-helper-label"
                id="door-select-helper"
                value={doorVariant}
                onChange={handleChangeType}
            >
                <MenuItem value={0}>schiebetüren innenliegend</MenuItem>
                <MenuItem value={1}>schiebetüren vorlaufend</MenuItem>
            </Select>
            {/* <FormHelperText>Some important helper text</FormHelperText> */}
        </FormControl>
    )
}

export function DoorList(props) {

    if (props.doorVariant === 1) {
        //outer doors
        return (
            <div style={{ width: 320, maxHeight: 220, overflow: 'scroll', overflowX: 'hidden' }}>
                <DoorListButton callback={() => props.callback(1)} title="1 Fach" icon={<Fach1 />} selected={props.selected === 1} />
                <DoorListButton callback={() => props.callback(2)} title="2 Fächer" icon={<Fach2 />} selected={props.selected === 2} />
                <DoorListButton callback={() => props.callback(3)} title="3 Fächer" icon={<Fach3 />} selected={props.selected === 3} />
                <DoorListButton callback={() => props.callback(4)} title="4 Fächer" icon={<Fach4 />} selected={props.selected === 4} />
                <DoorListButton callback={() => props.callback(5)} title="1 x 2 Fächer" icon={<Fach1x2 />} selected={props.selected === 5} />
                <DoorListButton callback={() => props.callback(6)} title="2 x 2 Fächer" icon={<Fach2x2 />} selected={props.selected === 6} />
                <DoorListButton callback={() => props.callback(7)} title="3 x 2 Fächer" icon={<Fach3x2 />} selected={props.selected === 7} />
                <DoorListButton callback={() => props.callback(8)} title="4 x 2 Fächer" icon={<Fach4x2 />} selected={props.selected === 8} />
            </div>
        )
    } else {
        // innner doors cannot cover two levels so row selector is tricked to always show one level in selected option
        return (
            <div style={{ maxHeight: 220}}>
                <DoorListButton callback={() => props.callback(1)} title="1 Fach" icon={<Fach1 />} selected={props.selected === 1 || props.selected === 5} />
                <DoorListButton callback={() => props.callback(2)} title="2 Fächer" icon={<Fach2 />} selected={props.selected === 2 || props.selected === 6} />
                <DoorListButton callback={() => props.callback(3)} title="3 Fächer" icon={<Fach3 />} selected={props.selected === 3 || props.selected === 7} />
                <DoorListButton callback={() => props.callback(4)} title="4 Fächer" icon={<Fach4 />} selected={props.selected === 4 || props.selected === 8} />
            </div>
        )
    }
}

export function DoorListButton(props) {
    const classes = useStyles()
    return (
        <ListItem button onClick={props.callback} component={Link} to="#" selected={props.selected}>
            <ListItemIcon style={{ minWidth: 35 }}>
                {props.icon}
            </ListItemIcon>
            <span className={classes.listText}>{props.title}</span>
        </ListItem>
    )
}

export function DrawerList(props) {
    return (
        <div style={{ maxHeight: 220 }}>
            <ListCallbackButton callback={() => props.callback(1)} title="Volle Höhe" selected={props.selected === 1} />
            <ListCallbackButton callback={() => props.callback(2)} title="Halbe Höhe" selected={props.selected === 2} />
            <ListCallbackButton callback={() => props.callback(3)} title="1/3 Höhe" selected={props.selected === 3} />
        </div>
    )
}

export function BackplaneList(props) {
    return (
        <div style={{ maxHeight: 220 }}>
            <ListCallbackButton callback={() => props.callback(1)} title="Volle Länge" selected={props.selected === 1} />
            <ListCallbackButton callback={() => props.callback(2)} title="Fachbreit" selected={props.selected === 2} />
        </div>
    )
}

export function FeetList(props) {
    return(
    <div style={{ maxHeight: 220 }}>
        <ListCallbackButton callback={() => props.callback(0)} title="Niedriger Stellfuß" selected={props.selected === 0} />
        <ListCallbackButton callback={() => props.callback(1)} title="Hoher Stellfuß" selected={props.selected === 1} />
    </div>
    )
}

export function ListCallbackButton({ title, val, callback, selected }) {
    selected = selected || false
    const classes = useStyles()
    return (
        <ListItem button onClick={callback} selected={selected}>
            <span className={classes.listText}>{title}</span>
            {val && <span className={classes.dimension}>{val}</span>}
        </ListItem>
    )
}

export function DepthButton({ title, val, callback }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(val)
    const [custom, setCustom] = useState(false)
    const defaultValues = [330, 410]

    const handleChange = e => {
        const newVal = e.target.value
        if (newVal === "Eigene") {
            handleCustom()
        } else {
            setValue(Number(newVal) || '')
        }
    }
    const handleOpen = () => {
        setCustom(false)
        setOpen(true)
        setValue(val)
    }

    const handleSave = (newVal) => {
        setOpen(false);
        if (callback && newVal !== val) callback(newVal)
    }
    const handleAbort = () => {
        setOpen(false)
        setCustom(false)
    }
    const handleCustom = () => {
        setCustom(true)
    }

    const Selector = () => (
        <FormControl className='' fullWidth>
            <Select value={value} onChange={handleChange} >
                {defaultValues.includes(val) === false && <MenuItem value={val}>{val} mm</MenuItem>}
                {defaultValues.map(el => <MenuItem value={el}>{el} mm</MenuItem>)}
                <MenuItem value='Eigene'>Eigene</MenuItem>
            </Select>
        </FormControl>
    )

    return (
        <div>
            <ListItem button onClick={() => handleOpen()}>
                {title}
                <div className={classes.dimension}>{val} mm</div>
            </ListItem>
            <GenericDialog title={title} open={open} val={val} onSave={() => handleSave(value)} onAbort={() => handleAbort()}>
                {!custom &&
                    <Selector />
                }
                {custom &&
                    <TextField autoFocus margin="dense" label={title} onChange={handleChange} value={value} type="number" fullWidth />
                }
            </GenericDialog>
        </div>
    );
}

export function DepthModal({ title, val, defaultValues, callback, min, max }) {
    let history = useHistory()
    const [custom, setCustom] = useState(false)
    const [value, setValue] = useState(val)
    const defaultValues_mm = defaultValues.map(val => val * 1000)

    const handleChange = e => {
        const newVal = e.target.value
        if (newVal === "own") {
            setCustom(true)
        } else {
            setValue(Number(newVal) || '')
        }
    }

    const handleSave = () => {
        let newValue = value
        newValue = newValue > max ? max : newValue < min ? min : newValue
        if (callback && value !== val) callback(newValue)
        history.goBack()
    }

    return (
        <Dialog open>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {!custom &&
                    <FormControl className='' fullWidth>
                        <Select value={value} onChange={handleChange} >
                            {defaultValues_mm.includes(val) === false && <MenuItem value={val}>{val} mm</MenuItem>}
                            {defaultValues_mm.map(el => <MenuItem value={el}>{el} mm</MenuItem>)}
                            <MenuItem value='own'>Eigene</MenuItem>
                        </Select>
                    </FormControl>
                }
                {custom &&
                    <TextField autoFocus margin="dense" helperText={min + ' - ' + max + ' mm'} label={title} onChange={handleChange} value={value} type="number" fullWidth />
                }
                <DialogActions>
                    <Button onClick={() => history.goBack()}>abbrechen</Button>
                    <Button onClick={() => handleSave()}>OK</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export function ModalInput({ title, val, defaultValues, callback, min, max }) {
    let history = useHistory()
    const [custom, setCustom] = useState(false)
    const [value, setValue] = useState(val)
    const defaultValues_mm = defaultValues.map(val => val * 1000)

    const handleChange = e => {
        const newVal = e.target.value
        if (newVal === "own") {
            setCustom(true)
        } else {
            setValue(Number(newVal) || '')
        }
    }

    const handleSave = () => {
        let newValue = value
        newValue = newValue > max ? max : newValue < min ? min : newValue
        if (callback && value !== val) callback(newValue)
        history.goBack()
    }

    return (
        <Dialog open>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {!custom &&
                    <FormControl className='' fullWidth>
                        <Select value={value} onChange={handleChange} >
                            {defaultValues_mm.includes(val) === false && <MenuItem value={val}>{val} mm</MenuItem>}
                            {defaultValues_mm.map((el, index) => <MenuItem key={index} value={el}>{el} mm</MenuItem>)}
                            <MenuItem value='own'>Eigene</MenuItem>
                        </Select>
                    </FormControl>
                }
                {custom &&
                    <TextField autoFocus margin="dense" helperText={min + ' - ' + max + ' mm'} label={title} onChange={handleChange} value={value} type="number" fullWidth />
                }
                <DialogActions>
                    <Button onClick={() => history.goBack()}>abbrechen</Button>
                    <Button onClick={() => handleSave()}>OK</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export function HeightModal({ title, val, defaultValues, callback, min, max }) {
    let { rowNum } = useParams()
    const colHeight = val[rowNum].height * 1000 - 19
    const defaultOutervalues = defaultValues.map(val => val - 0.019)
    
    return (
        <ModalInput title={title} val={colHeight} defaultValues={defaultOutervalues} callback={(newVal) => callback(newVal + 19, rowNum)} min={min} max={max} />
        )
    }
    
    
export function ColsModal({ title, val, min, max, callback }) {
    let history = useHistory()
    const classes = useStyles()
    const [value, setValue] = useState(val)
    const options = []
    for (let i = min; i <= max; i++) {
        options.push(i)
    }
    
    const handleChange = e => {
        setValue(Number(e.target.value) || '')
    };
    const handleSave = () => {
        if (callback && value !== val) callback(value)
        history.goBack()
    }
    
    return (
        <GenericDialog title={title} open={true} onSave={() => handleSave(value)} onAbort={() => history.goBack()}>
            <FormControl className={classes.formControl}>
                <Select value={value} onChange={handleChange}>
                    {options.map((el, i) => <MenuItem key={i} value={el}>{el} Elemente</MenuItem>)}
                </Select>
            </FormControl>
        </GenericDialog>
    )
}

export function LocaleModal({title, callback, val, options, onSaveto}) {
    let history = useHistory()
    const classes = useStyles()
    const [value, setValue] = useState(val)


    const handleChange = e => {
        // console.log(e.target.value)
        setValue(e.target.value)
    };
    const handleSave = () => {
        if (callback && value !== val) callback(value)     
        //history.goBack()
        //history.nextPath('/konfigurator')
    }

    return (
        <LocalDialog title={title} open={true} onSave={() => handleSave(value)} onAbort={() => history.goBack()} onSaveto={onSaveto}>
            <FormControl className={classes.formControl}>
                <Select value={value} onChange={handleChange}>
                    {options.map((el, i) => <MenuItem key={i} value={el}>{el.fullname} {el.vat}</MenuItem>)}
                </Select>
            </FormControl>
        </LocalDialog>
    )
}


function LocalDialog({ open, title, onSave, onAbort, children, onSaveto }) {
    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onAbort()}>abbrechen</Button>
                <Button component={Link} to={onSaveto} onClick={() => onSave()}>OK</Button>
            </DialogActions>
        </Dialog>
    );
}

function GenericDialog({ open, title, onSave, onAbort, children }) {
    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onAbort()}>abbrechen</Button>
                <Button onClick={() => onSave()}>OK</Button>
            </DialogActions>
        </Dialog>
    );
}


export function NextButton({ to, prev, next, children }) {
    return (
        <Button
            component={Link}
            size="small"
            startIcon={prev && <IosArrowBack style={{ width: 15 }}/>}
            endIcon={next && <IosArrowForward style={{ width: 15 }} />}
            to={to}
            style={{ position: 'absolute', left: prev && 5, right: next && 5, fontSize: 14, textTransform: 'lowercase'}}
        >
            {children}
        </Button>
    );
}

export function NextButtonContainer({ children }) {
    return (
        <div
            style={{
                position: 'relative',
                padding: 0,
                height: 33,
            }}
        >
            {children}
        </div>
    );
}
