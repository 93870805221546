import { doorMaterialModel } from "../../datamodels"
import { IDoorActions, IStore } from "../../global"
import { doorHelper } from "../../utils/Helper"

export const createDoorSlice = (setProduce:any) => {
	
	const doors:IDoorActions = {
		switchSelector: function (type: 0 | 1): void {
			setProduce((state:IStore) => {state.config.doors.selector = type})
		},
		setVisible: function (show: boolean): void {
			setProduce((state:IStore) => {state.config.doors.visible = show})
		},
		clickedAt: function (width, height, pos): void {
			// nicer way: state.doors.length === clearedArray.length -> not cleard, so add new door :)
			setProduce((state:IStore) => {
				const clearedArray = state.config.doors.list.filter(door => {
					if (doorHelper.is_within_other(pos, door.pos, door.size)) return false
        	if (doorHelper.other_is_within(pos, door.pos, { x: width, y: height })) return false
        	return true
				})
				if (state.config.doors.list.find(door => doorHelper.is_at_position(pos, door.pos))) {
					state.config.doors.list = clearedArray
				} else {
					state.config.doors.list = [...clearedArray, {
						type: state.config.doors.variant as 0|1, // #FIX this is duplette - same as Doorselector in door Type
						size: { x: width, y: height },
						pos: pos
					}]
				}
			})
		},
		create: function (newDoors): void {
			setProduce((state:IStore) => {state.config.doors.list = newDoors})
		},
		// #TODO Name could be more clear; Type of Doors (1 Cell, 2 Cells...) In Edit Door Mode 
		editGroup: function (doorGroup): void {
			setProduce((state:IStore) => {state.config.doors.edit = doorGroup})
		},
		changeGrid: (newGrid) => setProduce((state:IStore) => {
			const newDoors = state.config.doors.list.filter(door => {
				if(door.pos.y + door.size.y - 1 > state.config.boards.list.length) return false 
				if(door.pos.x + door.size.x > newGrid) return false
				return true
			})
			state.config.doors.list = newDoors
		}),

		setMaterial:(newMaterial: number): void => setProduce((state:IStore) => {state.config.doors.material = doorMaterialModel[newMaterial]}),
	}

	return {	
		doors
	}
}