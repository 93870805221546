import React, { createRef } from 'react';
import * as THREE from 'three'
import PropTypes from 'prop-types'

class Board extends React.Component {
    static defaultProps = {
        xPos: 0,
        yPos: 0,
        zPos: 0,
        thickness: 0.019,
        rotation:{x:0,y:0,z:0},
        textureRotation: {x:0,y:0,z:0},
        translate: {x:0.5,y:0.5,z:0.5},
        visible: true,
        name: "board",
        callback: ()=>null
    }
    constructor(props) {
        super(props)
        this.cubeRef = createRef()
        this.createMesh()
        this.updateMesh()
    }
    createMesh() {
        const material = this.getMaterial()
        const geometry = new THREE.BoxBufferGeometry(1, 1, 1);
        this.setTextureRotation(geometry)
        geometry.translate(this.props.translate.x,this.props.translate.y,this.props.translate.z)
        this.cube = new THREE.Mesh(geometry, material)
        this.cube.name = this.props.name
        this.cubeRef.current = this.cube
        if(this.props.parentRef) this.props.parentRef.current = this.cube
        this.rotateCube(this.cube)
    }
    setTextureRotation(el) {
        el.rotateX(this.props.textureRotation.x * Math.PI)
        el.rotateY(this.props.textureRotation.y * Math.PI)
        el.rotateZ(this.props.textureRotation.z * Math.PI)
    }
    rotateCube(el) {
        this.cube.rotation.set(this.props.rotation.x * Math.PI, this.props.rotation.y * Math.PI, this.props.rotation.z * Math.PI)
    }

    getMaterial() {
        return this.props.material
    }
    updateMesh() {
        const {length,thickness,depth,visible,xPos,yPos,zPos} = this.props;
        this.setVisible(visible)
        this.setSize(length,depth,thickness)
        this.setX(xPos).setY(yPos).setZ(zPos)
    }
 
    componentDidMount() {
        this.props.scene.add(this.cube)
    }
    componentWillUnmount() {
        this.props.scene.remove(this.cube)
        this.cube.geometry.dispose();
        console.log("Board unmounted")
    }
    componentDidUpdate(prevProps) {
        const {length,thickness,depth,visible,xPos,yPos,zPos} = this.props;
        if(prevProps.material !== this.props.material) this.setMaterial(this.props.material)
        if(prevProps.visible !== visible) this.setVisible(visible)
        // this.setSize(length,depth,thickness)
        if(prevProps.length !== length) this.setLength(length)
        if(prevProps.depth !== depth) this.setDepth(depth)
        if(prevProps.thickness !== thickness) this.setThickness(thickness)
        if(prevProps.xPos !== xPos) this.setX(xPos)
        if(prevProps.yPos !== yPos) this.setY(yPos)
        if(prevProps.zPos !== zPos) this.setZ(zPos)
        // this.updateMesh()
    }
 
    setSize(l,d,t) {
        this.setLength(l).setDepth(d).setThickness(t)
        return this
    }
    setLength(l) {
        this.cube.scale.x = l
        return this
    }
    setDepth(d) {
        this.cube.scale.z = d
        return this
    }
    setThickness(t) {
        this.cube.scale.y = t
        return this
    }
    setMaterial(mat) {
        this.cube.material = mat
    }
    setX(xPos) {
        this.cube.position.x = xPos
        return this
    }
    setY(yPos) {
        this.cube.position.y = yPos
        return this
    }
    setZ(zPos) {
        this.cube.position.z = zPos
        return this
    }

    setVisible(visible) {
        this.cube.visible = visible
    }



    render() {
        return (
            <>
                {this.props.children}
            </>
        )
    }
}

Board.propTypes = {
    scene: PropTypes.object.isRequired,
    length: PropTypes.number.isRequired,
    material: PropTypes.oneOfType([
        PropTypes.array.isRequired,
        PropTypes.object.isRequired
    ]),
    thickness: PropTypes.number,
    depth: PropTypes.number.isRequired,
    visible: PropTypes.bool.isRequired,
    xPos: PropTypes.number,
    yPos: PropTypes.number
}

export default Board