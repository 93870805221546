import React from 'react'
import './App.scss'
import logo from './sanktjohanser_schriftzug.svg'
import { ConnectedScene } from './three_components/ThreeScene'
import PerspectiveControls from './components/perspective_controls/perspective_controls'
import { HorizontalDimension, VerticalDimension } from './components/dimension_controls/dimension_controls'
import {
  ParentButton,
  NextButtonContainer,
  NextButton,
  Header,
  ListButton,
  ListCallbackButton,
  DepthModal,
  ColsModal,
  MaterialSelector,
  DoorList,
  MaterialElement,
  CardBox,
  ModalInput,
  HeightModal,
  DrawerList,
  BackplaneList,
  LocaleModal,
} from './components/material_interfaces/material_interfaces'
import { List } from '@material-ui/core'
import AppContext from './Context'
import CallContainer from './CallContainer'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Footer from './components/footer/footer'
import * as lookup from './datamodels';
import PrintView from './components/print/print'
import { InfoBox } from './components/infobox/infobox'
import { useStore } from './store/store'
import { getMaxGrid, getMinGrid } from './utils/Helper'
import { ApiConnector } from './utils/API/ApiConnector'
import ApiFetch from './utils/API/ApiFetch'


class App extends React.Component {
  constructor(props) {
    super(props)
    // TODO boards, cols, doors & drawers could be separated; might be easier to modularize
    // also state data could be in an separate module to clean up structure here
    this.state = {
    }
  }

  render() {
    const {boards, grid, width, depth, onChangeWidth, setProduce, switchShelfMaterialType, setDoorMaterial, setWallMounted, localFactor, setLocale} = this.props
    const depthBlueprint = lookup.blueprint.depth
    const footHeight = this.props.footHeight < 4 ? lookup.blueprint.footHeights[this.props.footHeight] : 0
    const fullHeight = Math.round((this.props.boards.reduce((pv, cv) => pv + cv.height, 0) + lookup.blueprint.thickness + footHeight) * 1000)
    const gridSize = (width - lookup.blueprint.thickness) / grid
    const minGrid = getMinGrid(width, lookup.blueprint.gridMax)
    const maxGrid = getMaxGrid(width, lookup.blueprint.gridMin)
    const materialName = this.props.shelfMaterial.name
    const treatmentName = this.props.shelfMaterial.treatment ? (", " + this.props.treatment.name) : ""
    
    return (
      <div className="App">
        <AppContext.Provider value={this.state}>
          <Router>
            <Switch>
              <Route path="/konfigurator/analog">
                <div className="center-box">
                  <div className="logo-box" alt="Sanktjohanser">
                    <img src={logo} alt="Sanktjohanser" />
                  </div>
                  <div id="sj-shelf-container" className="sj-shelf-container" >
                    {/* for print we need: perspective, doors visible, drawers visible */}
                    <div className="threeView" id="3dView">
                      <ConnectedScene />
                    </div>
                    <PerspectiveControls />
                  </div>
                  <InfoBox 
                    title='Analog' 
                    species=''
                    type={materialName}
                    treatment={treatmentName}
                    width={width}
                    height={fullHeight}
                    depth={depth}
                    vat={localFactor.vat}
                    country={localFactor.name}
                    />                  
                    <Route path="/konfigurator/analog/get/:id?">
                      <ApiFetch />
                    </Route>
                    <Route path="/konfigurator/analog/save">
                      <ApiConnector />
                    </Route>
                  {!(localStorage.getItem('sj-local')) && <Redirect to="/konfigurator/analog/locale" /> }
                   <Route path="/konfigurator/analog/locale">
                      <LocaleModal title="Standort" val={localFactor} options={lookup.localFactors} callback={newLocale => setLocale(newLocale)} onSaveto='/konfigurator/analog' />
                    </Route>
                  <Switch>
                    <Route path="/konfigurator/analog/structure">
                        <HorizontalDimension width={width} />
                        <VerticalDimension elements={boards} boardPositions={this.props.boardToScreenPositions} />
                      {/* <DepthDimension depth={depth} /> */}
                      <CardBox>
                        <Header
                          title="Regalstruktur"
                          text="bestimmen sie die größe und einteilung des regals"
                        />
                        <NextButtonContainer>
                          <NextButton prev to="/konfigurator/analog">
                            Hauptmenü
                          </NextButton>
                        </NextButtonContainer>
                        <List disablePadding>
                          <ListButton title="Regalbreite" val={width * 1000} units="mm" to="/konfigurator/analog/structure/edit/width" ></ListButton>
                          <ListButton title="Regaltiefe" val={depth * 1000} units="mm" to="/konfigurator/analog/structure/edit/depth" ></ListButton>
                          <ListButton title="Rastermaß" val={grid} units={`x ${Math.round(gridSize * 1000)} mm`} to="/konfigurator/analog/structure/edit/cols" ></ListButton>
                        </List>
                      </CardBox>
                      <Route path="/konfigurator/analog/structure/edit/width">
                        <ModalInput title="Regalbreite" val={width * 1000} defaultValues={lookup.blueprint.width} callback={newVal => onChangeWidth(newVal / 1000)}  min={lookup.blueprint.minWidth * 1000} max={this.props.shelfMaterial.maxWidth * 1000} />
                      </Route>
                      <Route path="/konfigurator/analog/structure/edit/depth">
                        <DepthModal title="Regaltiefe" defaultValues={depthBlueprint} val={depth * 1000} callback={(newVal) => setProduce((store) => { store.config.depth = newVal / 1000 })} min={lookup.blueprint.minDepth*1000} max={lookup.blueprint.maxDepth*1000} />
                      </Route>
                      <Route path="/konfigurator/analog/structure/edit/cols">
                        <ColsModal title="Rastermaß" val={grid} min={minGrid} max={maxGrid} callback={(newVal) => this.props.onChangeGrid(newVal)} />
                      </Route>
                      <Route path="/konfigurator/analog/structure/edit/row/:rowNum">
                        <HeightModal title="Fachhöhe" val={boards} defaultValues={lookup.blueprint.colHeight} callback={(newVal, rowNum) => this.props.changeRowHeight(newVal / 1000, rowNum)} min={lookup.blueprint.minColHeight * 1000} max={lookup.blueprint.maxColHeight * 1000} />
                      </Route>
                      <CallContainer
                        mount={() => setProduce(state => {state.config.boards.edit = true; state.config.doors.visible = false })}
                        unmount={() => setProduce(state => { state.config.boards.edit = false; state.config.doors.visible = true })}
                      />
                    </Route>
                    <Route path="/konfigurator/analog/material/type">
                      <CardBox>
                        <Header
                          title="Material"
                          text=""
                        />
                        <NextButtonContainer>
                          <NextButton prev to="/konfigurator/analog">
                            Hauptmenü
											</NextButton>
                          <NextButton next to="/konfigurator/analog/material/species">
                            Holzart / Oberfläche
											</NextButton>
                        </NextButtonContainer>
                        <ListCallbackButton title="Multiplex" callback={() => { switchShelfMaterialType(0) }} selected={this.props.shelfMaterial.type === 0} />
                        <ListCallbackButton title="Massiv" callback={() => { switchShelfMaterialType(1) }} selected={this.props.shelfMaterial.type === 1} />
                      </CardBox>
                    </Route>
                    <Route path="/konfigurator/analog/material/species">
                      <CardBox>
                        <Header
                          title="Holzart / Oberfläche"
                          text=""
                        />
                        <NextButtonContainer>
                          <NextButton prev to="/konfigurator/analog/material/type">
                            Material
											</NextButton>
                          {this.props.shelfMaterial.treatment && <NextButton next to="/konfigurator/analog/material/treatment">Oberfläche</NextButton>}
                          {!this.props.shelfMaterial.treatment && <NextButton next to="/konfigurator/analog">Hauptmenü</NextButton>}
                        </NextButtonContainer>
                        <List disablePadding style={{ maxHeight: '340px', overflow: 'auto' }}>
                          {lookup.shelfMaterialModel.map((material, i) => {
                            if (this.props.shelfMaterial.type === material.type) {
                              return <MaterialElement key={i} title={lookup.shelfMaterialModel[i].name} selected={i === this.props.shelfMaterial.id} id={i} callback={() => this.props.switchShelfMaterialSpecies(i)} val="" units="" />
                            }
                            return null
                          })}
                        </List>
                      </CardBox>
                    </Route>
                    <Route path="/konfigurator/analog/material/treatment">
                      <CardBox>
                        <Header
                          title="Material"
                          text=""
                        />
                        <NextButtonContainer>
                          <NextButton prev to="/konfigurator/analog/material/species">
                            Material / Holzart
											</NextButton>
                          <NextButton next to="/konfigurator/analog">Hauptmenü</NextButton>
                        </NextButtonContainer>
                        <ListCallbackButton title={lookup.woodTreatments[0].name} callback={() => this.props.setMaterialTreatment(0)} selected={this.props.treatment.id === 0} />
                        <ListCallbackButton title={lookup.woodTreatments[1].name} callback={() => this.props.setMaterialTreatment(1)} selected={this.props.treatment.id === 1} />
                        <ListCallbackButton title={lookup.woodTreatments[2].name} callback={() => this.props.setMaterialTreatment(2)} selected={this.props.treatment.id === 2} />
                        <ListCallbackButton title={lookup.woodTreatments[3].name} callback={() => this.props.setMaterialTreatment(3)} selected={this.props.treatment.id === 3} />
                      </CardBox>
                    </Route>
                    <Route path="/konfigurator/analog/door/material">
                      <CardBox>
                        <Header
                          title="Material Schiebetüren"
                          text=""
                        />
                        <NextButtonContainer>
                          <NextButton prev to="/konfigurator/analog">Hauptmenü</NextButton>
                          <NextButton next to="/konfigurator/analog/door/set">Typ / Größe</NextButton>
                        </NextButtonContainer>
                        <ListCallbackButton title={lookup.doorMaterialModel[0].name} callback={() => setDoorMaterial(0)} selected={this.props.doorMaterial.id === 0} />
                        <ListCallbackButton title={lookup.doorMaterialModel[1].name} callback={() => setDoorMaterial(1)} selected={this.props.doorMaterial.id === 1} />
                        <ListCallbackButton title={lookup.doorMaterialModel[2].name} callback={() => setDoorMaterial(2)} selected={this.props.doorMaterial.id === 2} />
                        {lookup.doorMaterialModel[3].shelfReference.includes(this.props.shelfMaterial.id) &&
                          <ListCallbackButton title={lookup.doorMaterialModel[3].name} callback={() => setDoorMaterial(3)} selected={this.props.doorMaterial.id === 3} />}
                        {lookup.doorMaterialModel[4].shelfReference.includes(this.props.shelfMaterial.id) &&
                          <ListCallbackButton title={lookup.doorMaterialModel[4].name} callback={() => setDoorMaterial(4)} selected={this.props.doorMaterial.id === 4} />}
                      </CardBox>
                      <CallContainer
                        key='editMaterial'
                        mount={() => setProduce(store => { store.config.doors.visible = true })}
                      />
                    </Route>
                    <Route path="/konfigurator/analog/door/set">
                      <CardBox>
                        <Header
                          title="Schiebetüren"
                          text=""
                        />
                        <NextButtonContainer>
                          <NextButton prev to="/konfigurator/analog/door/material">Material Schiebetüren</NextButton>
                          <NextButton next to="/konfigurator/analog">Hauptmenü</NextButton>
                        </NextButtonContainer>
                        <MaterialSelector doorVariant={this.props.doors.variant} callback={(variant) => this.props.setProduce(state => { state.config.doors.variant = variant })} />
                        <DoorList selected={this.props.doors.edit} callback={e => this.props.setProduce(state => { state.config.doors.edit = e })} doorVariant={this.props.doors.variant} />
                      </CardBox>
                      <CallContainer
                        key='editPositions'
                        mount={() => this.props.setProduce(state => { state.config.doors.edit = 1; state.config.doors.visible = true })}
                        unmount={() => this.props.setProduce(state => { state.config.doors.edit = 0 })}
                      />
                    </Route>
                    <Route path="/konfigurator/analog/drawer/material" >
                      <CardBox>
                        <Header
                          title="Frontmaterial Schubladen"
                          text="Wählen sie das Frontmaterial der Schubläden."
                        />
                        <NextButtonContainer>
                          <NextButton prev to="/konfigurator/analog">Hauptmenü</NextButton>
                          <NextButton next to="/konfigurator/analog/drawer/set">Größe</NextButton>
                        </NextButtonContainer>
                        <ListCallbackButton title={lookup.drawerMaterialModel[0].name} callback={() => setProduce(store => { store.config.drawers.material = lookup.drawerMaterialModel[0] })} selected={this.props.drawerMaterial.id === 0} />
                        <ListCallbackButton title={lookup.drawerMaterialModel[1].name} callback={() => setProduce(store => { store.config.drawers.material = lookup.drawerMaterialModel[1] })} selected={this.props.drawerMaterial.id === 1} />
                        <ListCallbackButton title={lookup.drawerMaterialModel[2].name} callback={() => setProduce(store => { store.config.drawers.material = lookup.drawerMaterialModel[2] })} selected={this.props.drawerMaterial.id === 2} />
                        <ListCallbackButton title={lookup.drawerMaterialModel[3].name} callback={() => setProduce(store => { store.config.drawers.material = lookup.drawerMaterialModel[3] })} selected={this.props.drawerMaterial.id === 3} />
                      </CardBox>
                      <CallContainer
                        key="editMaterial"
                        mount={() => setProduce(state => {state.config.doors.visible = false})}
                        unmount={() => setProduce(state => {state.config.doors.visible = true})}
                      />
                    </Route>
                    <Route path="/konfigurator/analog/drawer/set" >
                      <CardBox>
                        <Header
                          title="Schubladen"
                          text=""
                        />
                        <NextButtonContainer>
                          <NextButton prev to="/konfigurator/analog/drawer/material">Frontmaterial Schubläden</NextButton>
                          <NextButton next to="/konfigurator/analog">Hauptmenü</NextButton>
                        </NextButtonContainer>
                        <DrawerList callback={e => setProduce(store => {store.config.drawers.edit = e })} selected={this.props.editDrawers} />
                      </CardBox>
                      <CallContainer
                        key="editMaterial"
                        mount={() => setProduce(store => { store.config.doors.visible = false })}
                        unmount={() => setProduce(store => { store.config.doors.visible = true })}
                      />
                      <CallContainer
                        key="editPosiitons"
                        mount={() => setProduce(store => { store.config.drawers.edit = 1 })}
                        unmount={() => setProduce(store => { store.config.drawers.edit = 0 })}
                      />
                    </Route>
                    <Route path="/konfigurator/analog/back/material" >
                      <CardBox>
                        <Header
                          title="Rückwände"
                          text="Wählen sie das Material für die Rückwände."
                        />
                        <NextButtonContainer>
                          <NextButton prev to="/konfigurator/analog">Hauptmenü</NextButton>
                          {this.props.wallMounted === false && <NextButton next to="/konfigurator/analog/back/set">Größe / Position</NextButton>}
                        </NextButtonContainer>
                        <ListCallbackButton title={lookup.backplaneMaterialModel[0].name} callback={() => setProduce(store => { store.config.backplanes.material = lookup.backplaneMaterialModel[0] })} selected={this.props.backplaneMaterial.id === 0} />
                        <ListCallbackButton title={lookup.backplaneMaterialModel[1].name} callback={() => setProduce(store => { store.config.backplanes.material = lookup.backplaneMaterialModel[1] })} selected={this.props.backplaneMaterial.id === 1} />
                        {lookup.backplaneMaterialModel.map((material, i) => {
                            if (lookup.backplaneMaterialModel[i].shelfReference.includes(this.props.shelfMaterial.id)) {
                              return <ListCallbackButton key={i} title={lookup.backplaneMaterialModel[i].name} callback={() => setProduce(store => { store.config.backplanes.material = lookup.backplaneMaterialModel[i] })} selected={this.props.backplaneMaterial.id === i} />
                            }
                            return null
                          })

                        }
                      </CardBox>
                      <CallContainer
                        key="editMaterial"
                        mount={() => setProduce(store => { store.config.doors.visible = false; store.config.drawers.visible = false })}
                        unmount={() => setProduce(store => { store.config.doors.visible = true; store.config.drawers.visible = true })}
                      />
                    </Route>
                    <Route path="/konfigurator/analog/back/set" >
                      <CardBox>
                        <Header
                          title="Rückwände"
                          text="Positionieren sie die Rückwände"
                        />
                        <NextButtonContainer>
                          <NextButton prev to="/konfigurator/analog/back/material">Material</NextButton>
                          <NextButton next to="/konfigurator/analog">Hauptmenü</NextButton>
                        </NextButtonContainer>
                        {/* <BackplaneList callback={e => setProduce(store => {store.config.backplanes.edit = e })} selected={this.props.editBackplanes} /> */}
                        <BackplaneList callback={e => setProduce(store => {store.config.backplanes.edit = e })} selected={this.props.editBackplanes} />
                      </CardBox><CallContainer
                        key="editMaterial"
                        mount={() => setProduce(store => { store.config.doors.visible = false; store.config.drawers.visible = false })}
                        unmount={() => setProduce(store => { store.config.doors.visible = true; store.config.drawers.visible = true })}
                      />
                      <CallContainer
                        key="editPosiitons"
                        mount={() => setProduce(store => { store.config.backplanes.edit = 1 })}
                        unmount={() => setProduce(store => { store.config.backplanes.edit = 0 })}
                      />
                    </Route>
                    <Route path="/konfigurator/analog/feet/variant" >
                      <CardBox>
                        <Header
                          title="Stellfuß"
                          text=""
                        />
                        <NextButtonContainer>
                          <NextButton prev to="/konfigurator/analog/feet/height">Höhe</NextButton>
                          <NextButton next to="/konfigurator/analog">Hauptmenü</NextButton>
                        </NextButtonContainer>
                        <ListCallbackButton callback={() => setProduce(store => { store.config.feet.variant = 0 })} title="Stellfuß rund" selected={this.props.footVariant === 0} />
                        <ListCallbackButton callback={() => setProduce(store => { store.config.feet.variant = 1 })} title="Stellfuß rechteckig" selected={this.props.footVariant === 1} />
                      </CardBox>
                    </Route>
                    <Route path="/konfigurator/analog/feet/height" >
                      <CardBox>
                        <Header
                          title="Stellfuß / Wandmontage"
                          text=""
                        />
                        <NextButtonContainer>
                          <NextButton prev to="/konfigurator/analog">Hauptmenü</NextButton>
                          {(this.props.footHeight !== 0 && this.props.footHeight !== 4) && <NextButton next to="/konfigurator/analog/feet/variant">Form</NextButton>}
                        </NextButtonContainer>
                        <List disablePadding>
                          <MaterialElement title="Stellfußhöhe" val={lookup.blueprint.footHeights[0] * 1000} units="mm" callback={() => setProduce(store => { store.config.feet.height = 0; store.config.wallMounted = false })} selected={this.props.footHeight === 0} />
                          <MaterialElement title="Stellfußhöhe" val={lookup.blueprint.footHeights[1] * 1000} units="mm" callback={() => setProduce(store => { store.config.feet.height = 1 ; store.config.wallMounted = false })} selected={this.props.footHeight === 1} />
                          <MaterialElement title="Stellfußhöhe" val={lookup.blueprint.footHeights[2] * 1000} units="mm" callback={() => setProduce(store => { store.config.feet.height = 2 ; store.config.wallMounted = false })} selected={this.props.footHeight === 2} />
                          <MaterialElement title="Stellfußhöhe" val={lookup.blueprint.footHeights[3] * 1000} units="mm" callback={() => setProduce(store => { store.config.feet.height = 3 ; store.config.wallMounted = false }) && this.setBackplanes()} selected={this.props.footHeight === 3} />                          
                          <MaterialElement title="Wandmontage" val={" "} units="" callback={() => setWallMounted()} selected={this.props.footHeight === 4} />
                        </List>
                      </CardBox>
                    </Route>
                    <Route path="/konfigurator/analog">
                      <CardBox>
                        <Header
                          title="Hauptmenü"
                          text=""
                        />
                        <List disablePadding>
                          <ParentButton to="/konfigurator/analog/structure">Regalstruktur</ParentButton>
                          <ParentButton to="/konfigurator/analog/material/type">Material</ParentButton>
                          <ParentButton to="/konfigurator/analog/door/material">Schiebetüren</ParentButton>
                          <ParentButton to="/konfigurator/analog/drawer/material">Schubladen</ParentButton>
                          <ParentButton to="/konfigurator/analog/back/material">Rückwände</ParentButton>
                          <ParentButton to="/konfigurator/analog/feet/height">Stellfuß / Wandmontage</ParentButton>
                          <ParentButton to="/print/analog">Drucken</ParentButton>
                          <ParentButton to="/konfigurator/analog/save">Speichern</ParentButton>
                        </List>
                      </CardBox>
                    </Route>
                  </Switch>
                </div>
                <Footer material={materialName} treatment={treatmentName} shelfWidth={width} shelfHeight={fullHeight} shelfDepth={depth} />
              </Route>
              <Route path="/print/analog">
                <PrintView />
                <CallContainer
                  mount={() => this.props.setPerspective(0)}
                />
              </Route>
              <Redirect exact from="/" to="/konfigurator/analog" />
            </Switch>
          </Router>
        </AppContext.Provider>
      </div>
    )
  }
}


const ConnectedApp = () => {
  const camPerspective = useStore(store => store.config.perspective)
  const width = useStore(store => store.config.width)
  const depth = useStore(store => store.config.depth)
  const footHeight = useStore(store => store.config.feet.height)
  const shelfMaterial = useStore(store => store.config.material)
  const setProduce = useStore(store => store.setProduce) // #TODO where are they used - consolidate1
  const setState = useStore(store => store.setState) // #TODO where are they used - consolidate1
  const boards = useStore(store => store.config.boards.list)
  const footVariant = useStore(store => store.config.feet.variant)
  const grid = useStore(store => store.config.grid)
  const treatment = useStore(store => store.config.treatment)
  const editDoorGroup = useStore(store => store.doors.editGroup)
  const editDrawers =  useStore(store => store.drawers.create)
  const editBackplanes = useStore(store => store.config.backplanes.edit)
  const backplaneMaterial = useStore(store => store.config.backplanes.material)
  const boardToScreenPositions = useStore(store => store.config.boardToScreenPositions)
  const drawerMaterial = useStore(store => store.config.drawers.material)
  const doors = useStore(store => store.config.doors)
  const doorMaterial = useStore(store => store.config.doors.material)
  const doorSelector = useStore(store => store.config.doors.selector)
  const localFactor = useStore(store => store.config.localFactor)
  const wallMounted = useStore(store => store.config.wallMounted)
  const setBackplanes = useStore(store => store.backplanes.create)
  const setWallmounted = useStore(store => store.setWallMounted)
  const setEditDoorGroup = useStore(store => store.doors.editGroup)
  const changeDoorSelector = useStore(store => store.doors.switchSelector)
  const changeShelfmaterial = useStore(store => store.setMaterial)
  const onChangeWidth = useStore(store => store.setWidth)
  const onChangeDepth = useStore(store => store.setDepth)
  const onChangeGrid = useStore(store => store.setGrid)
  const switchShelfMaterialSpecies = useStore(store => store.setMaterialSpecies)
  const switchShelfMaterialType = useStore(store => store.setMaterialType)
  const setMaterialTreatment = useStore(store => store.setMaterialTreatment)
  const changeRowHeight = useStore(store => store.rows.setHeight)
  const setDoorMaterial = useStore(store => store.doors.setMaterial)
  const setPerspective = useStore(store => store.setPerspective)
  const setLocale = useStore(store => store.setLocale)
  

  return (<App
    camPerspective = {camPerspective}
    backplaneMaterial={backplaneMaterial}
    boards={boards}
    boardToScreenPositions={boardToScreenPositions}
    changeDoorSelector={changeDoorSelector}
    changeRowHeight={changeRowHeight}
    changeShelfmaterial={changeShelfmaterial}
    depth={depth}
    doorMaterial = {doorMaterial}
    doorSelector = {doorSelector}
    drawerMaterial = {drawerMaterial}
    editBackplanes={editBackplanes}
    editDoorGroup={editDoorGroup}
    editDrawers={editDrawers}
    footHeight={footHeight}
    footVariant={footVariant}
    grid={grid}
    wallMounted={wallMounted}
    localFactor={localFactor}
    onChangeDepth={onChangeDepth}
    onChangeGrid={onChangeGrid}
    setBackplanes={setBackplanes}
    setEditDoorGroup={setEditDoorGroup}
    setProduce={setProduce}
    setState={setState}
    setPerspective={setPerspective}
    setWallMounted={setWallmounted}
    shelfMaterial={shelfMaterial}
    switchShelfMaterialSpecies={switchShelfMaterialSpecies}
    switchShelfMaterialType={switchShelfMaterialType}
    setMaterialTreatment={setMaterialTreatment}
    treatment={treatment}
    width={width} onChangeWidth={onChangeWidth}
    setDoorMaterial={setDoorMaterial}
    setLocale={setLocale}
    doors={doors}
  />)
}

// export default addUrlProps({urlPropsQueryConfig})(App)
export default ConnectedApp