import * as THREE from 'three'
import Door from './../door'

export default class InnerDoor extends Door {
  static defaultProps = {

  }

  createObject() {
    const { length, height, depth, visible, xPos, yPos, zPosInset } = this.props
    const material = this.getMaterial()
    this.material = material
    this.linematerial = new THREE.MeshBasicMaterial({ color: 0x000000, transparent: true, opacity: 1 })
    const innerGeometry = new THREE.BoxGeometry(1, 1, 1)
    const leftLineGeometry = new THREE.BoxGeometry(1, 1, 1)
    innerGeometry.rotateX(Math.PI * 0.5)
    innerGeometry.translate(0.5, 0.5, -0.5)    
    leftLineGeometry.translate(0.5, 0.5, -0.5)
    const door = new THREE.Group()
    const cube = new THREE.Mesh(innerGeometry, material)
    const leftLine = new THREE.Mesh(leftLineGeometry, this.linematerial)    
    const rightLine = new THREE.Mesh(leftLineGeometry, this.linematerial)

    // const bottomShift = bottom ? boardThickness : boardThickness / 2
    // outerCube.scale.set(length - (this.shadowJoint * 2), height + boardThickness - this.shadowJoint, this.hplThickness)
    // outerCube.position.set(0 + boardThickness / 2 + (this.shadowJoint), -bottomShift + this.shadowJoint / 2, 0)

    cube.scale.set(length - 0.002, height, 0.002)
    leftLine.scale.set(0.001, height, 0.002)
    rightLine.scale.set(0.001, height, 0.002)
    cube.position.set(0.001, 0, -0.009)
    leftLine.position.set(0, 0, -0.009)    
    rightLine.position.set(length - 0.001, 0, -0.009)
    door.add(cube)
    door.add(leftLine)    
    door.add(rightLine)
    this.outerCube = cube
    this.leftLine = leftLine
    this.rightLine = rightLine
    this.door = door;
    this.setPos(xPos, yPos, depth - zPosInset * 0.009)
    this.door.visible = visible
  }

  scaleObject() {
    const { length, height, zPosInset } = this.props
    this.outerCube.scale.set(length - 0.002, height, 0.002)    
    this.leftLine.scale.set(0.001, height, 0.002)     
    this.rightLine.scale.set(0.001, height, 0.002)     
    this.rightLine.position.set(length-0.001, 0, -0.009 - zPosInset * 0.009)
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) this.door.visible = this.props.visible
    if (prevProps.length !== this.props.length || prevProps.height !== this.props.height) this.scaleObject()
    if (prevProps.material !== this.props.material) this.setMaterial(this.props.material)
    if (prevProps.top !== this.props.top || prevProps.bottom !== this.props.bottom || prevProps.height !== this.props.height) this.setTopBottom()
    this.setPos(this.props.xPos, this.props.yPos, this.props.depth - this.props.zPosInset * 0.009) // TODO could be checked if changed     
               
}

  componentWillUnmount() {
    this.props.scene.remove(this.door)
    this.outerCube.geometry.dispose();
    console.log("Door unmounted")
  }
  
}