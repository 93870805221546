import React from 'react';
import './footer.scss'
import Price from './price/price';

export default function Footer({material, treatment, shelfWidth, shelfHeight, shelfDepth}) {

    return(
        <div className="footer-container">
          <div className="footer-center-box">
            <div className="footer-name footer-big-text">Analog</div>
            <div className="footer-material footer-small-text">{material}{treatment}</div>
            <div className="footer-size footer-small-text">{shelfWidth * 1000} x {Math.round(shelfDepth * 1000)} x {shelfHeight} mm</div>
            <div className="footer-price footer-big-text"><Price /></div>
            <div className="footer-netto footer-small-text">euro netto</div>
          </div>
        </div>
    )
}
