import { IRowActions, IStore } from "../../global"

export const createRowSlice = (setProduce:any) => {
	const rows:IRowActions = {
		setHeight: (newHeight, rowNum) => setProduce((S:IStore) => {
			S.config.boards.list[rowNum].height = newHeight
		}),
		toggleBoard: (rowNum, boardNum) => setProduce((S:IStore) => {
			const board = S.config.boards.list[rowNum].cols[boardNum]
			board.visible = !board.visible
		}),
		create: (newRowHeight) => setProduce((S:IStore) => {
			const newRow = {height: newRowHeight, cols:[] as Array<{visible:boolean}>}
			for(let i = 0; i < S.config.grid - 1; i++) {
				newRow.cols.push({visible: i % 2 === 0 ? false : true})
			}
			S.config.boards.list.push(newRow)
		}),
		delete: () => setProduce((S:IStore) => {
			S.config.boards.list.pop() // #TODO: could also delete Doors etc. here
		})
	}
	return {
		rows
	}
}