import React from 'react'
import * as THREE from 'three'
import PropTypes from 'prop-types'

export default class Door extends React.Component {

    static defaultProps = {
        hplThickness: 0.003,
        shadowJoint: 0.003,
    }

    constructor(props) {
        super(props);
        this.hplThickness = 0.003
        this.shadowJoint = 0.003
        this.createObject()
    }

    createObject() {
        const { boardThickness, length, height, depth, bottom, top, visible, xPos, yPos } = this.props
        const material = this.getMaterial()
        const innerMaterial = this.getInnerMaterial()
        this.material = material
        this.innerMaterial = innerMaterial
        const innerGeometry = new THREE.BoxBufferGeometry(1, 1, 1)
        innerGeometry.rotateX(Math.PI * 0.5)
        innerGeometry.translate(0.5, 0.5, 0.5)
        const outerGeometry = innerGeometry.clone()
        const door = new THREE.Group()
        const innerCubeDown = new THREE.Mesh(innerGeometry, innerMaterial)
        const innerCubeUp = new THREE.Mesh(innerGeometry, innerMaterial)
        const innerCubeLeft = new THREE.Mesh(innerGeometry, innerMaterial)
        const innerCubeRight = new THREE.Mesh(innerGeometry, innerMaterial)
        const outerCube = new THREE.Mesh(outerGeometry, material)
        const bottomShift = bottom ? boardThickness : boardThickness / 2
        const extraHeight = (bottom && top) ? boardThickness : (bottom || top) ? boardThickness / 2 : 0
        outerCube.scale.set(length, height + boardThickness + extraHeight - this.shadowJoint, this.hplThickness)
        outerCube.position.set(0, -bottomShift + this.shadowJoint / 2, 0.001)
        innerCubeDown.scale.set(length - 2 * boardThickness, 0.02, 0.005)
        innerCubeUp.scale.set(length - 2 * boardThickness, 0.02, 0.005)
        innerCubeLeft.scale.set(0.02, height - 2 * boardThickness, 0.005)
        innerCubeRight.scale.set(0.02, height - 2 * boardThickness, 0.005)
        innerCubeDown.position.set(boardThickness, 0, -0.004)
        innerCubeUp.position.set(boardThickness, height - boardThickness , -0.004)
        innerCubeLeft.position.set(boardThickness, boardThickness , -0.004)
        innerCubeRight.position.set(length - boardThickness - 0.02, boardThickness , -0.004)
        door.add(innerCubeDown)
        door.add(innerCubeUp)
        door.add(innerCubeLeft)
        door.add(innerCubeRight)
        door.add(outerCube)
        this.outerCube = outerCube
        this.innerCubeDown = innerCubeDown        
        this.innerCubeUp = innerCubeUp
        this.innerCubeLeft = innerCubeLeft
        this.innerCubeRight = innerCubeRight
        this.door = door;
        this.setPos(xPos, yPos, depth)
        this.door.visible = visible
    }

    getMaterial() {
        return this.props.material
    }
    getInnerMaterial() {
        return this.props.innerMaterial
    }
    setMaterial(newMat) {
        this.outerCube.material = newMat
    }
    //set outerdoor overshoot if its placed on first row or last row
    setTopBottom() {
        const {bottom, top, length, height, boardThickness} = this.props
        // const bottomShift = bottom ? boardThickness : boardThickness / 2
        const extraHeight = (bottom && top) ? boardThickness : (bottom || top) ? boardThickness / 2 : 0
        this.outerCube.scale.set(length, height + boardThickness + extraHeight - this.shadowJoint, this.hplThickness)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.visible !== this.props.visible) this.door.visible = this.props.visible
        if (prevProps.length !== this.props.length || prevProps.height !== this.props.height) this.scaleObject()
        if (prevProps.material !== this.props.material) this.setMaterial(this.props.material)
        if (prevProps.top !== this.props.top || prevProps.bottom !== this.props.bottom || prevProps.height !== this.props.height) this.setTopBottom()
        this.setPos(this.props.xPos, this.props.yPos, this.props.depth) // TODO could be checked if changed                
    }

    setPos(xPos, yPos, depth) {
        // const shift_xPos = xPos + this.shadowJoint * 2
        this.door.position.set(xPos || 0, yPos || 0, depth)
    }

    scaleObject() {
        const { length, boardThickness, height } = this.props     
        const extraHeight = (this.props.bottom || this.props.top) ? this.props.boardThickness / 2 : 0
        this.outerCube.scale.set(length, height + boardThickness + extraHeight - this.shadowJoint, this.hplThickness)
        this.innerCubeDown.scale.set(length - 2 * boardThickness, 0.02, 0.005)
        this.innerCubeUp.scale.set(length - 2 * boardThickness, 0.02, 0.005)
        this.innerCubeUp.position.set(boardThickness, height - boardThickness , -0.004)
        this.innerCubeLeft.scale.set(0.02, height - 2 * boardThickness, 0.005)
        this.innerCubeRight.scale.set(0.02, height - 2 * boardThickness, 0.005)        
        this.innerCubeRight.position.set(length - boardThickness - 0.02, boardThickness , -0.004)        
    }

    componentDidMount() {
        this.props.scene.add(this.door)
    }
    componentWillUnmount() {
        this.props.scene.remove(this.door)
        this.outerCube.geometry.dispose();
        this.innerCubeDown.geometry.dispose();        
        this.innerCubeUp.geometry.dispose();
        this.innerCubeLeft.geometry.dispose();
        this.innerCubeRight.geometry.dispose();
        console.log("Door unmounted")
    }

    render() {
        return (
            null
        )
    }
}

Door.propTypes = {
    scene: PropTypes.object.isRequired,
    length: PropTypes.number.isRequired,
    material: PropTypes.oneOfType([
        PropTypes.array.isRequired,
        PropTypes.object.isRequired
    ]),
    depth: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    visible: PropTypes.bool.isRequired,
    xPos: PropTypes.number.isRequired,
    yPos: PropTypes.number.isRequired,
    boardThickness: PropTypes.number.isRequired
}