import React from 'react'
import PropTypes from 'prop-types'

export default class CallContainer extends React.Component {
    static defaultProps = {
      mount: ()=>null,
      unmount:()=>null
  }
    componentDidMount() {
      this.props.mount()
    }
    componentWillUnmount() {
      this.props.unmount()
    }
    render() {return(null)}
  }

  CallContainer.propTypes = {
      mount: PropTypes.func,
      unmount: PropTypes.func
  }