import { IPos } from "../global"

export const getMinGrid = (width:number, gridMax:number) => Math.ceil(width / gridMax)
export const getMaxGrid = (width:number, gridMin:number) => Math.floor(width/ gridMin)

//Helper Functions ++++++++++++++++++++++++++++++++++++++++++++++++
export const doorHelper = {
	is_at_position:(clickPos:IPos, otherPos:IPos) => (clickPos.x === otherPos.x && clickPos.y === otherPos.y),
	is_within_other:(clickPos:IPos, otherPos:IPos, otherSize:{x:1|2|3|4, y:1|2}) => ((clickPos.x + 1 <= otherPos.x + otherSize.x && clickPos.x >= otherPos.x) && (clickPos.y + 1 <= otherPos.y + otherSize.y && clickPos.y >= otherPos.y)),
	other_is_within:(clickPos:IPos, otherPos:IPos, thisSize:{x:1|2|3|4, y:1|2}) => ((clickPos.x <= otherPos.x && clickPos.x + thisSize.x - 1 >= otherPos.x) && (clickPos.y <= otherPos.y && clickPos.y + thisSize.y - 1 >= otherPos.y)),
}