import { IBackplaneActions, IStore } from "../../global"

export const createBackplaneSlice = (setProduce:any) => {
	const backplanes:IBackplaneActions = {
		create: function (row, col, full): void {
			setProduce((store:IStore) => {
				const selectedRow = store.config.backplanes.list[row]
				if(full === true) {
					// same size so just toggle cover
					selectedRow.full = !selectedRow.full
					selectedRow.cover = []
				} else {
					// else new size so set to new array
					selectedRow.full = false
					const oldCol = selectedRow.cover[col] || 0
					selectedRow.cover[col] = oldCol === 0 ? 1 : 0
				}
			})
		}
	}
	return {
		backplanes
	}
}