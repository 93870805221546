import React from 'react';
import './infobox.scss'
import Price from '../footer/price/price';
import { Link } from 'react-router-dom';

export function InfoBox({ title, species, type, treatment, width, height, depth, vat, country }) {

  return (
    <div className="info-container">
      <div className="info-box">
        <div className='info-title'>{title}</div>
        <div className='size-container'>
          <span className='width-info'>{width * 1000} x </span>
          <span className='depth-info'>{depth * 1000} x </span>
          <span className='height-info'>{height} mm</span>
        </div>
        <div className='material-container'>
          <span className='type-info'>{type}</span>
          <span className='species-info'>{species}</span>
          <span className='treatment-info'>{treatment}</span>
        </div>
      </div>
      <div className='price-container'>
        <div className='price-amount'><Price/> €</div>
        <div className='vat-info'><Link to="/konfigurator/analog/locale">{vat}</Link></div>
        {/* <div component={Link} to='/konfigurator/select/locale' className='country-info'>{country}</div> */}
        <Link to="/konfigurator/analog/locale">{country}</Link>
        
      </div>
    </div>
  )
}