import React from 'react';
import * as THREE from 'three'
import DrawerSelector from './drawerSelector'
import AppContext from './../../../Context'
import { blueprint } from '../../../datamodels';
import produce from 'immer';

export default class DrawerSelectors extends React.Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.selectGroup_1 = new THREE.Group()
    this.selectGroup_2 = new THREE.Group()
    this.selectGroup_3 = new THREE.Group()

    this.activeObject = undefined;
    this.material = new THREE.MeshBasicMaterial({ color: 0x0000FF, transparent: true })
    this.selectGroup_1.visible = this.props.editDrawers === 1
    this.selectGroup_2.visible = this.props.editDrawers === 2
    this.selectGroup_3.visible = this.props.editDrawers === 3
  }

  onMouseMove = (e) => {
    const intersections = this.props.raycaster.intersectObjects([this.selectGroup_1, this.selectGroup_2, this.selectGroup_3], true)
    if (intersections[0]) {
      if (intersections[0].object.parent !== this.activeObject) {
        this.clearActiveObject()
        this.activeObject = intersections[0].object.parent
        this.activeObject.onMouseOver()
      }
      //same object, do nothing
    } else {
      this.clearActiveObject()
    }
  }

  clearActiveObject() {
    if (this.activeObject) this.activeObject.onMouseOut()
    this.activeObject = undefined
  }

  onMouseClicked = (e) => {
    const intersections = this.props.raycaster.intersectObjects([this.selectGroup_1, this.selectGroup_2, this.selectGroup_3], true)
    if (intersections[0]) {
      intersections[0].object.parent.onMouseClicked()
    }
  }

  drawerClicked = (row, col, height, number) => {
    const drawers = produce(this.props.drawerModel, drawersCopy => {
      const unit = drawersCopy[row][col] || {height: 1, number:0}

      // set drawers are of same type as new one so add or remove
      if(unit.height === height) {
        if(number <= unit.number) {
          unit.number --
        } else {
          unit.number ++
        }
      } else {
        // else simply replace by new
        unit.height = height
        unit.number = 1
      }
      drawersCopy[row][col] = unit
    })
    this.props.callback(drawers)

    // const drawersCopy = [...this.props.drawerModel]
    // const unit = {...drawersCopy[row][col]} || {height: 1, number:0}
  
    // // set drawers are of same type as new one so add or remove
    // if(unit.height === height) {
    //   if(number <= unit.number) {
    //     unit.number --
    //   } else {
    //     unit.number ++
    //   }
    // } else {
    //   // else simply replace by new
    //   Object.assign(unit,{height:height, number:1})
    // }
    // drawersCopy[row][col] = unit
    // this.props.callback(drawersCopy)
  }

  componentDidMount() {
    this.props.scene.add(this.selectGroup_1, this.selectGroup_2, this.selectGroup_3)
    window.addEventListener('pointermove', this.onMouseMove, false)
    window.addEventListener('pointerdown', this.onMouseClicked, false)
  }

  componentWillUnmount() {
    window.removeEventListener('pointermove', this.onMouseMove)
    window.removeEventListener('pointerdown', this.onMouseClicked)
    this.props.scene.remove(this.selectGroup_1, this.selectGroup_2, this.selectGroup_3)
  }
  componentDidUpdate() {
    this.selectGroup_1.visible = this.props.editDrawers === 1
    this.selectGroup_2.visible = this.props.editDrawers === 2
    this.selectGroup_3.visible = this.props.editDrawers === 3
  }


  render() {
    const { boards, width, grid, depth, thickness } = this.props
    const colSize = (width - blueprint.thickness) / grid
    let totalHeight = 0
    const drawers = boards.map((board, y) => {
      let row = []
      for (let x = 0; x < grid; x++) {
        let multiplier = 1
        const wall = board.cols[x] || { visible: true } //last one always visible
        const nextWall = board.cols[x + 1] || {visible:true}
        if(wall.visible === false) {
          multiplier ++
          if(nextWall.visible === false) multiplier ++
        }
        // const dWidth = wall.visible ? colSize - thickness - 0.006 : colSize * 2 - thickness - 0.006
        const dWidth = colSize * multiplier - thickness - 2 * blueprint.shadowJoint
        const dX = x * colSize + blueprint.thickness + blueprint.shadowJoint
        const col = x
        row.push(<DrawerSelector key={'1.1' + y + '.' + col} callback={() => this.drawerClicked(y, col, 1, 1)} scene={this.selectGroup_1} material={this.material.clone()} width={dWidth} height={board.height - blueprint.thickness - 2 * blueprint.shadowJoint} depth={depth} xPos={dX} yPos={totalHeight + thickness + blueprint.shadowJoint} />)
        row.push(<DrawerSelector key={'2.1' + y + '.' + col} callback={() => this.drawerClicked(y, col, 2, 1)} scene={this.selectGroup_2} material={this.material.clone()} width={dWidth} height={(board.height - blueprint.thickness - 3 * blueprint.shadowJoint) / 2} depth={depth} xPos={dX} yPos={totalHeight + thickness + blueprint.shadowJoint + (board.height - blueprint.thickness - blueprint.shadowJoint) / 2} />)
        row.push(<DrawerSelector key={'2.2' + y + '.' + col} callback={() => this.drawerClicked(y, col, 2, 2)} scene={this.selectGroup_2} material={this.material.clone()} width={dWidth} height={(board.height - blueprint.thickness - 3 * blueprint.shadowJoint) / 2} depth={depth} xPos={dX} yPos={totalHeight + thickness + blueprint.shadowJoint} />)
        row.push(<DrawerSelector key={'3.1' + y + '.' + col} callback={() => this.drawerClicked(y, col, 3, 1)} scene={this.selectGroup_3} material={this.material.clone()} width={dWidth} height={(board.height - blueprint.thickness - 4 * blueprint.shadowJoint) / 3} depth={depth} xPos={dX} yPos={totalHeight + thickness + blueprint.shadowJoint + ((board.height - blueprint.thickness - blueprint.shadowJoint) / 3) * 2} />)
        row.push(<DrawerSelector key={'3.2' + y + '.' + col} callback={() => this.drawerClicked(y, col, 3, 2)} scene={this.selectGroup_3} material={this.material.clone()} width={dWidth} height={(board.height - blueprint.thickness - 4 * blueprint.shadowJoint) / 3} depth={depth} xPos={dX} yPos={totalHeight + thickness + blueprint.shadowJoint + (board.height - blueprint.thickness - blueprint.shadowJoint) / 3} />)
        row.push(<DrawerSelector key={'3.3' + y + '.' + col} callback={() => this.drawerClicked(y, col, 3, 3)} scene={this.selectGroup_3} material={this.material.clone()} width={dWidth} height={(board.height - blueprint.thickness - 4 * blueprint.shadowJoint) / 3} depth={depth} xPos={dX} yPos={totalHeight + thickness + blueprint.shadowJoint} />)
        
        if (!wall.visible) x += multiplier - 1 //crazy shit - x changes the value in callback if col is x :/
      }
      totalHeight += board.height
      return row
    })

    return (
      drawers
    )
  }
}
