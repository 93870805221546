import React from 'react';
import * as THREE from 'three'
import shadowImg from './../static/shadowEdge.png'

export default class FakeShadow extends React.Component {
    
    constructor(props) {
        super(props)
        const texLoader = new THREE.TextureLoader()
        const shadowTex = texLoader.load(shadowImg)
        const planeSize = 1
        const shadowGeo = new THREE.PlaneBufferGeometry(planeSize, planeSize)
        const shadowMat = new THREE.MeshBasicMaterial({
            map: shadowTex,
            transparent: true,
            depthWrite: false
        })
        this.shadowMesh = new THREE.Mesh(shadowGeo, shadowMat)
        this.shadowMesh.name = "Shadow Bottom"
        this.shadowMesh.receiveShadow = false
        this.shadowMesh.material.opacity = 1 / (props.footHeight+0.1) / 15
        this.shadowMesh.rotation.x = -Math.PI / 2

        this.shadowMesh.position.x = 0
        this.shadowMesh.position.y = 0.001
        this.shadowMesh.position.z = 0
        
        this.shadowMesh.scale.x = props.width * 1.5
        this.shadowMesh.scale.y = props.depth * 4
        // this.secondShadowMesh = this.shadowMesh.clone()
        // this.secondShadowMesh.scale.x = props.width * 1.45
        // this.secondShadowMesh.scale.y = 1.3

    }

    componentDidMount() {
        this.props.scene.add(this.shadowMesh)
                // this.props.scene.add(this.secondShadowMesh)
    }

    componentDidUpdate() {
        this.shadowMesh.scale.x = this.props.width  * 1.5
        this.shadowMesh.scale.y = this.props.depth  * 4
        this.shadowMesh.material.opacity = 1 / (this.props.footHeight+0.1) / 15
    }



    render() {
        return null
    }
}
