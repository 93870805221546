import Backplane from "./../backplane";


export default class BackplaneSelector extends Backplane {

  constructor(props) {
    super(props)
    this.mouseOver = false
    this.box.onMouseOver = () => this.onMouseOver()
    this.box.onMouseOut = () => this.onMouseOut()
    this.box.onMouseClicked = () => this.onMouseClicked()
    this.box.material.opacity = 0.1
  }

  onMouseOver = () => {
    if (!this.mouseOver) {
      this.box.material.opacity = 0.3
      this.mouseOver = true
    }
  }
  setMaterial(newMat) {
  }
  onMouseOut = () => {
    if (this.mouseOver) {
      this.box.material.opacity = 0.1
      this.mouseOver = false
    }
  }
  onMouseClicked = () => {
    if (this.mouseOver) {
      this.props.callback()
    }
  }

}