import React from 'react';
import Board from './Board'

function VBoard(props) {
    const rotB = Math.random() < 0.5 ? 0 : 1
    const inset = 0.016
    const isInset = props.backplaneFull ? 1 : 0
    const insetDepth = props.depth - (inset * isInset)
    const insetZPos = props.zPos + (inset * isInset)
    return <Board {...props} zPos={insetZPos} depth={insetDepth} rotation={{x:0,y:0,z:0.5}} textureRotation={{x:0,y:0,z:rotB}} translate={{x:0.5,y:-0.5,z:0.5}}  />
}

export default VBoard