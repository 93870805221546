//@ts-nocheck
import produce from "immer";
import create from "zustand";
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { ILocalFactor, IShelfMaterial, IStore } from "../global";
import { initState } from "./initState";
import { createBackplaneSlice } from "./slices/backplaneSlice";
import { createDoorSlice } from "./slices/doorSlice";
import { createDrawerSlice } from "./slices/drawerSlice";
import { createFeetSlice } from "./slices/feetSlice";
import { createRowSlice } from "./slices/rowSlice";
import { createTablearSlice } from "./slices/tabelarSlice";
import { getMaxGrid, getMinGrid } from "../utils/Helper";
import { API_DOMAIN, backplaneMaterialModel, blueprint, doorMaterialModel, drawerMaterialModel, shelfMaterialModel, woodTreatments } from "../datamodels";

// const rows = {
// 	setHeight: (height:number, row:number, state:IStore) => state.config.boards.list[row].height = height
// }
type ISetStore = (state: IStore) => void;
type ISetProduce = (fn:ISetStore) => void


export const useStore = create<IStore>((set,get) => {

	const config = {...initState} // set backup config
	//store Helper Functions
	const setState = (chunk:object) => set(produce(() => (chunk))) //even easier
	const setProduce:ISetProduce = (fn) => set(produce(fn))

	// Public Functions
	const setLocale = (localFactor:ILocalFactor) => {
		setProduce(store => {
			store.config.localFactor = localFactor
			localStorage.setItem('sj-local', localFactor.id.toString())
		})
	}

	const setPerspective = (view:0|1|2|3) => {
		setProduce((store) => {store.config.perspective = view})
	}
	const setBoardToScreenPositions = (newPositions:Array<number>) => {
		setProduce((store) => {store.config.boardToScreenPositions = newPositions})
	}
	const setDepth = (newDepth:number) => {
		setProduce((store) => {store.config.depth = newDepth})
	}
	const setGrid = (newGrid:number) => {
		setDoorGrid(newGrid)
		setProduce(state => {
			// state.doors.changeGrid(newGrid) //ob das geht 0o??
			state.config.boards.list.map((board) => {
				const cols = board.cols
				const newCols:any[] = [] // new Array
				for(let i = 0; i < newGrid - 1; i++) {
					const newCol = cols[i] || { visible: true }
          newCols.push(newCol)
				}
				board.cols = newCols
				return board
			})
			state.config.grid = newGrid
		})
	}
	const setDoorGrid = (newGrid:number) => {
		setProduce(state => {
			const newDoors = state.config.doors.list.filter(door => {
				if(door.pos.y + door.size.y - 1 > state.config.boards.list.length) return false
				if(door.pos.x + door.size.x > newGrid) return false
				return true
			})
			state.config.doors.list = newDoors
		})
	}

	const setWallMounted = () => {
		setProduce((state) => {
			state.config.boards.list.map((_, row) => {
				state.config.backplanes.list[row].full = false
				state.config.backplanes.list[row].cover = []
				for (let i=0; i <= state.config.boards.list[row].cols.length; i++) {
					state.config.backplanes.list[row].cover.push(1)
				}
				return null
			})
			state.config.feet.height = 4
			state.config.wallMounted = true;
		})
	}

	const setMaterial = (material:IShelfMaterial) => {
		setProduce((state) => {
			state.config.material = material
		})
	}

	const setWidth = (newWidth:number) => {
		const minGrid = getMinGrid(newWidth, blueprint.gridMax)
		const maxGrid = getMaxGrid(newWidth, blueprint.gridMin)
		setProduce((state) => {
			const colSize = (state.config.width - blueprint.thickness) / state.config.grid
			const preferredGrid = Math.round(newWidth / colSize)
			const alternativeGrid = maxGrid - preferredGrid > preferredGrid - minGrid ? minGrid : maxGrid
			const newGrid = (maxGrid >= preferredGrid && preferredGrid >= minGrid) ? preferredGrid : alternativeGrid //#TODO I think it should be >= -> also in UI Grid selector
			if(newGrid !== state.config.grid) setGrid(newGrid)
			state.config.width = newWidth
		})
	}

	const setMaterialSpecies = (nr:number) => {
		setProduce((state) => {
			const newShelfMaterialReference = shelfMaterialModel[nr].matReference //why are we doing this? future safeness?
			const newShelfMaterial = shelfMaterialModel[newShelfMaterialReference]
			const newWidth = state.config.width > newShelfMaterial.maxWidth ? newShelfMaterial.maxWidth : state.config.width
			const tmpDoorMaterial = state.config.doors.material
			const tmpDrawerMaterial = state.config.drawers.material.matReference
			const tmpBackplaneMaterial = state.config.backplanes.material.matReference
			state.config.material = newShelfMaterial
			if(newWidth !== state.config.width) setWidth(newWidth) //lets see if changing state in setProduce is ok? #TODO
			if(tmpDoorMaterial.shelfReference.length > 0 && !tmpDoorMaterial.shelfReference.includes(nr as never)) state.config.doors.material = doorMaterialModel[0]
			if(tmpDrawerMaterial === 'ref' && newShelfMaterial.treatment === false) state.config.drawers.material = drawerMaterialModel[0]
			if(tmpBackplaneMaterial === 'ref' && newShelfMaterial.treatment === false) state.config.backplanes.material = backplaneMaterialModel[0]
		})
	}

	const setMaterialType = (type:number) => {
		setProduce((state) => {
			const fallback = shelfMaterialModel[state.config.material.fallback]
			const newWidth = state.config.width > fallback.maxWidth ? fallback.maxWidth : state.config.width
			if(newWidth !== state.config.width) setWidth(newWidth)
			if(type !== state.config.material.type) state.config.material = fallback;
		})
	}
	const setMaterialTreatment = (treatment:number) => {
		setProduce(state => {
			state.config.treatment = woodTreatments[treatment]
		})
	}

	const _fetchConfig = async (uri:string) => {
		// const response = await fetch(uri)
		await fetch(`${API_DOMAIN}/product/${uri}`)
		.then(r => r.json())
		.then(data => {
			const pData = JSON.parse(data)
			if(pData?.depth) {
				setProduce((state) => {
					state.config = pData
				})
			}
			console.log(pData)
		})
		.catch(err => console.log(err))
		//set({config: await response.json()})
	}

	const _saveConfig = async (uri:string) => {
		const baseUrl = `${API_DOMAIN}/product`
		const jConfig = JSON.stringify(get().config)
		const body = JSON.stringify(
			{
				uri: uri,
				reference: "analog",
				configuration: jConfig
			},null,2
		)
		console.log("Request body: ")
		console.log(body)	
		const requestOptions = {
			method: "POST",
			mode: 'cors',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: body
		}
		await fetch(baseUrl, requestOptions)
		.then(response => response.json())
		.then(data => console.log(data))
		.catch(err => console.log(err))
	}

	const _updateConfig = async (uri:string) => {
		const baseUrl = `${API_DOMAIN}/product`
		const jConfig = JSON.stringify(get().config)
		const body = JSON.stringify(
			{
				reference: "analog",
				configuration: jConfig
			},null,2
		)
		console.log("Request body: ")
		console.log(body)	
		const requestOptions = {
			method: "PUT",
			mode: 'cors',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: body
		}
		await fetch(baseUrl, requestOptions)
		.then(response => response.json())
		.then(data => console.log(data))
		.catch(err => console.log(err))
	}



	return {
		config,
		setProduce: setProduce,
		setState: setState,
		setPerspective: setPerspective,
		setBoardToScreenPositions: setBoardToScreenPositions,
		setDepth: setDepth,
		setGrid: setGrid,
		setMaterial: setMaterial,
		setWidth: setWidth,
		setWallMounted: setWallMounted,
		setMaterialSpecies: setMaterialSpecies,
		setMaterialType: setMaterialType,
		setMaterialTreatment: setMaterialTreatment,
		setLocale: setLocale,
		...createRowSlice(setProduce),
		...createDoorSlice(setProduce),
		...createBackplaneSlice(setProduce),
		...createDrawerSlice(setProduce),
		...createFeetSlice(setProduce),
		...createTablearSlice(setProduce),
		fetchConfig: _fetchConfig,
		saveConfig: _saveConfig,
		updateConfig: _updateConfig
	}
})


if (process.env.NODE_ENV === 'development') {
	//@ts-ignore
  mountStoreDevtool('Store', useStore);
}