import { useEffect } from "react";
import { useParams } from "react-router-dom"
import { useStore } from "../../store/store";

const selector = (store:any) => store.fetchConfig;

const ApiFetch = () => {
	
	
	let {id} = useParams<{id:string}>()
	const fetchConfig = useStore(selector)

	useEffect(() => {
		if(id) {
			console.log("Fetching :", id)
			fetchConfig(id)
		}
	},[id, fetchConfig])

	return (
		null
	)

}


export default ApiFetch