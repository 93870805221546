import React, { useEffect, useRef } from 'react';
import * as THREE from 'three'
import Foot from './foot'
import { blueprint } from '../../datamodels';

export default function Feet({ scene, boards, width, grid, depth, thickness, variant, wallMounted, heightIndex, footHeights}) {

  const lowFeet = useRef(new THREE.Group())
  const highFeet = useRef(new THREE.Group())

  const colSize = (width - blueprint.thickness) / grid
  const lowsideInset = 0.045
  const highsideInset = grid > 3 ? (colSize + thickness / 2) : 0.1
  const frontInset = 0.045
  const lowDir = 0.0275
  const highDir = 0.015
  const lowSize = 0.023
  const highSize = footHeights[heightIndex]
  const material = new THREE.MeshPhongMaterial({ color: 0x111111 })

  
  useEffect(function init() {
    const lowGroup = lowFeet.current
    const highGroup = highFeet.current
    scene.add(lowGroup, highGroup)
    return () => {
      scene.remove(lowGroup, highGroup)
    }
  }, [scene])

  useEffect(function changeVariant() {
    lowFeet.current.visible = heightIndex === 0
    highFeet.current.visible = heightIndex !== 0
  },[heightIndex])
  
  return (
    <>
    <FeetPair key='lowFeetStart' variant={0} group={lowFeet.current} material={material} xPos={lowsideInset} depth={depth} height={lowSize} dir={lowDir} inset={frontInset}/>
    <FeetPair key='highFeetStart' variant={variant} wallMounted={wallMounted} group={highFeet.current} material={material} xPos={highsideInset} depth={depth} height={highSize} dir={highDir} inset={frontInset}/>
    {boards[0].cols.map((col, x) => {
      if(col.visible) return <FeetPair key={'feet'+x} variant={0} group={lowFeet.current} material={material} xPos={x * colSize + colSize + thickness/2} depth={depth} height={lowSize} dir={lowDir} inset={frontInset} />
      return null
    })}
    {((width - 2 * (colSize + thickness / 2)) > 1.5) && <FeetPair key='middleFeetHigh' group={highFeet.current} variant={variant} wallMounted={wallMounted} material={material} xPos={width / 2} depth={depth} height={highSize} dir={highDir} inset={frontInset}/>}
    <FeetPair key='endFeetLow' group={lowFeet.current} variant={0} material={material} xPos={width - lowsideInset} depth={depth} height={lowSize} dir={lowDir} inset={frontInset} />
    <FeetPair key='endFeetHigh' group={highFeet.current} variant={variant} wallMounted={wallMounted} material={material} xPos={width - highsideInset} depth={depth} height={highSize} dir={highDir} inset={frontInset}/>
    </>
  )
}


function FeetPair({group,variant,wallMounted,xPos,depth, height, dir, material,inset}) {    
  return (
    <>
      <Foot scene={group} variant={variant} wallMounted={wallMounted} material={material} height={height} dir={dir} xPos={xPos} zPos={inset} />
      <Foot scene={group} variant={variant} wallMounted={wallMounted} material={material} height={height} dir={dir} xPos={xPos} zPos={depth - inset} />
    </>
  )
}