import { IFeetActions, IStore } from "../../global"

export const createFeetSlice = (setProduce:any) => {
	const feet:IFeetActions = {
		setHeight: function (newHeight: number): void {
			setProduce((state:IStore) => {state.config.feet.height = newHeight})
		},
		setVariant: function (variant: 0|1): void {
			setProduce((state:IStore) => {state.config.feet.variant = variant})
		}
	}
	return {
		feet
	}
}