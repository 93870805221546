import React from 'react';
import Board from './Board'

// class HBoard extends Board {
//     constructor(props) {
//         super(props)
//         this.cube.castShadow = true
//         this.cube.name = props.name || "Horizontal Board"
//         this.setZ(props.zPos || 0)
//     }

//     // setLength(l) {
//     //     this.cube.scale.x = l + this.props.thickness
//     //     return this
//     // }
//     // setThickness(t) {
//     //     this.cube.scale.y = t
//     //     return this
//     // }

// }

function HBoard(props) {
    return <Board {...props} />
}

export default HBoard