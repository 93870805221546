import OffDoor from './offDoors/offDoor'
import InnerDoor from './innerDoors/innerDoor'
import shallow from 'zustand/shallow'
import { blueprint } from '../../datamodels'
import { useStore } from '../../store/store'

export default function Doors({ scene, material, innerMaterial, boards, visible }) {
  const [width, depth, grid] = useStore(state => [state.config.width, state.config.depth, state.config.grid], shallow)
  const doorArray = useStore(state => state.config.doors.list)

  const colSize = (width - blueprint.thickness) / grid
  return (
    doorArray.map((door, i) => {
      const doorLength = door.size.x * colSize // calc door length
      const doorLevel = boards.slice(0, door.pos.y) //which vertical level/board the door is at
      const doorHeightFactor = (door.size.y === 1 || door.size.y === 2) ? door.size.y : 1 //must be 1 or 2
      const currentLevelHeight = boards[door.pos.y]?.height || null // get height of current story / level
      const nextLevelHeight = boards[door.pos.y + 1]?.height || null // get next of next story / level
      const doorHeight = doorHeightFactor === 1 ? currentLevelHeight - blueprint.thickness : currentLevelHeight + nextLevelHeight - blueprint.thickness // calculate full height of door
      if (doorHeight === null) return null // exit if shelf is smaller than door levels
      const doorXPos = door.pos.x * colSize + blueprint.thickness // calc real xPos
      const doorYPos = doorLevel.reduce((acc, curr) => acc + curr.height, 0) + blueprint.thickness // calc real yPos
      
      const top = (door.pos.y + door.size.y === boards.length)
      // const nextnextBoard = boards[i + 2] || {height:0}
      // const nexttop = (nextnextBoard.height === 0)

      if (door.type === 0) return <InnerDoor key={i} scene={scene} length={doorLength} depth={depth} height={doorHeight} visible={visible} material={material} xPos={doorXPos} yPos={doorYPos} zPosInset={0} boardThickness={blueprint.thickness} />
      if (door.type === 1) return <OffDoor key={i} scene={scene} bottom={door.pos.y === 0} top={top} colSize={colSize} length={doorLength} depth={depth} height={doorHeight} visible={visible} material={material} innerMaterial={innerMaterial} xPos={doorXPos} yPos={doorYPos} boardThickness={blueprint.thickness} />
      return null
    })
  )
}
