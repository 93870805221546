import React from 'react';
import * as THREE from 'three'

export default class Foot extends React.Component {
  
  constructor(props) {
    super(props)
    this.initObject()
    this.setScale(props.height, props.dir)
    this.setPosition(props.xPos,props.zPos)
    this.setVariant(props.variant, props.wallMounted)
  }

  initObject() {
    const {material} = this.props
    const squareGeometry = new THREE.BoxBufferGeometry(1,1,1)
    const roundGeometry = new THREE.CylinderBufferGeometry(1,1,1,20)
    squareGeometry.translate(0,0.5,0)
    roundGeometry.translate(0,0.5,0)
    this.squareFoot = new THREE.Mesh(squareGeometry, material)
    this.roundFoot = new THREE.Mesh(roundGeometry, material)
  }

  setScale(height,dir) {
    this.squareFoot.scale.set(0.01,height,0.06)
    this.roundFoot.scale.set(dir,height,dir)
  }

  setPosition(xPos, zPos) {
    this.squareFoot.position.set(xPos,-this.props.height,zPos)
    this.roundFoot.position.set(xPos,-this.props.height,zPos)
  }
  setVariant(variant, wallMounted) {
    if(variant === 0) {
      this.squareFoot.visible = false
      this.roundFoot.visible = true
    } else {
      this.squareFoot.visible = true
      this.roundFoot.visible = false
    }
    if(wallMounted === true) {
      this.squareFoot.visible = false
      this.roundFoot.visible = false
    }


  }

  componentDidMount() {
    this.props.scene.add(this.squareFoot, this.roundFoot)
  }
  componentWillUnmount() {
    this.props.scene.remove(this.squareFoot, this.roundFoot)
    this.squareFoot.geometry.dispose()
    this.roundFoot.geometry.dispose()
  }
  componentDidUpdate() {
    const {xPos, zPos, height, dir} = this.props
    this.setPosition(xPos,zPos)
    this.setScale(height,dir)
    this.setVariant(this.props.variant, this.props.wallMounted)    
  }

  render() {
    return null
  }

 
}