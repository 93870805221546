import { blueprint, w } from "../../../datamodels"
import { useStore } from "../../../store/store"

export default function Price() {
  const qdepth = useStore(state => state.config.depth)
  const qlength = useStore(state => state.config.width)
  const backplane_has_treatment = useStore(state => state.config.backplanes.material.treatment || false)
  const backplane_material_price = useStore(state => state.config.backplanes.material.price)
  const backplanes =  useStore(state => state.config.backplanes.list)
  const board_material_price = useStore(state => state.config.material.price) 
  const boards = useStore(state => state.config.boards.list)
  const length = 1000 * qlength || blueprint.width[5]*1000
  const grid = useStore(state => state.config.grid)
  const colSize = (length - 1000 * blueprint.thickness) / grid // size of one unit in mm
  const localFactor = useStore(state => state.config.localFactor)
  const footHeight = useStore(state => state.config.feet.heigth)
  const door_has_treatment = useStore(state => state.config.doors.material.treatment || false)
  const door_material_price = useStore(state => state.config.doors.material.price)
  const doors = useStore(state => state.config.doors.list)
  const drawer_material_price = useStore(state => state.config.drawers.material.price)
  const drawerfront_has_treatment = useStore(state => state.config.drawers.material.treatment || false)
  const drawers = useStore(state => state.config.drawers.list)
  const has_treatment = useStore(state => state.config.material.treatment)
  const tabelars = useStore(state => state.config.tabelars.list)
  const treatment_price = useStore(state => state.config.treatment.price)
  const treatment_setup = useStore(state => state.config.treatment.setup)
  const wallMounted = useStore(state => state.config.wallMounted || false)
  const width = 1000 * qdepth || blueprint.depth[0]*1000

  const calcDoorPrice = () => {
    let dSum = 0
    for (let i = 0; i < boards.length; i++) {
      const boardHeight = boards[i].height * 1000
      const nextBoard = boards[i + 1] || { height: 0 }
      const doorRow = doors[i] || []
      for (let j = 0; j < doorRow.length; j++) {
        const door = doorRow[j]
        if (door.size !== 0) {
          const doorLength = door.size.x * colSize
          const doorHeight = boardHeight - blueprint.thickness + nextBoard.height * 1000 * (door.size.y - 1)
          dSum += Door.Total(doorLength, doorHeight, door.type, door_material_price, treatment_price, door_has_treatment)
        }
      }

    }
    return dSum
  }

  const calcVPrices = () => {
    let bSum = 0
    for (let i = 0; i < boards.length; i++) {
      const cols = boards[i].cols
      const boardHeight = (boards[i].height - blueprint.thickness) * 1000
      for(let j = 0; j < cols.length; j++) {
        const col = cols[j]
        if(col.visible) {
          bSum += Verticalboard.Total(boardHeight,width-25,board_material_price,treatment_price, has_treatment, wallMounted)
        }
      }
      bSum += 2*Verticalboard.Total(boardHeight,width,board_material_price,treatment_price, has_treatment, wallMounted)
    }
    return bSum
  }

  const calcDrawerPrices = () => {
    let dSum = 0
    let drawer_front_price = drawer_material_price !== 'ref' ? drawer_material_price : board_material_price
    for (let i = 0; i < boards.length; i++) {
      const board = boards[i]
      for (let j = 0; j <= board.cols.length; j++) {
        const drawer = drawers[i]?.[j] || 0
        const wall = board.cols[j] || {visible:true}
        const nextWall = board.cols[j + 1] || {visible:true}
        const multiplier = wall.visible ? 1 : nextWall.visible ? 2 : 3 
        if (drawer.height > 0 && drawer.number !== 0) {       
          const drawerWidth = multiplier * colSize - 2 * 1000 * blueprint.shadowJoint
          const drawerHeight = 1000 * (board.height - blueprint.thickness - (drawer.height + 1) * blueprint.shadowJoint) / drawer.height
          dSum += Drawer.Total(drawerWidth, width, drawerHeight, drawer.number, drawer_front_price, board_material_price, treatment_price, drawerfront_has_treatment, has_treatment)
        }
        j += multiplier - 1
      }

    }
    return dSum

  }

  const calcTabelarPrices = () => {
    let tSum = 0
    for(let i = 0; i < boards.length; i ++) {
      const board = boards[i]
      for(let x = 0; x <= board.cols.length; x++) {
          const tabelar = tabelars[i]?.[x] || 0
          const wall = board.cols[x] || {visible:true}
          const nextWall = board.cols[x + 1] || {visible:true}
          const multiplier = wall.visible ? 1 : nextWall.visible ? 2 : 3           
          const tabelarLength = multiplier * colSize - 1000 * blueprint.thickness
          if(board.height > 0.2 && tabelar[0] === 1) {tSum += Tabelar.Total(tabelarLength, width-25, board_material_price,treatment_price, has_treatment)}
          if(board.height > 0.5 && tabelar[1] === 1) {tSum += Tabelar.Total(tabelarLength, width-25, board_material_price,treatment_price, has_treatment)}
          if(board.height > 0.5 && tabelar[2] === 1) {tSum += Tabelar.Total(tabelarLength, width-25, board_material_price,treatment_price, has_treatment)}
          if(board.height > 0.7 && tabelar[3] === 1) {tSum += Tabelar.Total(tabelarLength, width-25, board_material_price,treatment_price, has_treatment)}
          if(board.height > 0.7 && tabelar[4] === 1) {tSum += Tabelar.Total(tabelarLength, width-25, board_material_price,treatment_price, has_treatment)}
          if(board.height > 0.7 && tabelar[5] === 1) {tSum += Tabelar.Total(tabelarLength, width-25, board_material_price,treatment_price, has_treatment)}
          x += multiplier - 1        
      }
    }
    return tSum
  }

  const calcBackplanePrices = () => {
    let bSum = 0
    for(let i = 0; i < boards.length; i ++) {
      const board = boards[i]
      const boardHeight = board.height * 1000
      const backplane = backplanes[i]
      if(backplane?.full) {
        bSum += Backplane.Total((length - 1000 * 2 * blueprint.thickness), boardHeight - 1000 * blueprint.thickness, backplane_material_price, treatment_price, backplane_has_treatment)
      } else {
        // calculate single backplanes
        for(let x = 0; x < grid; x++) {
          const wall = board.cols[x] || {visible:true}
          const nextWall = board.cols[x + 1] || {visible:true}
          const multiplier = wall.visible ? 1 : nextWall.visible ? 2 : 3 
          if(backplane?.cover?.[x]) bSum += Backplane.Total(colSize - 1000 * blueprint.thickness + (multiplier - 1) * colSize, boardHeight - 1000 * blueprint.thickness, backplane_material_price, treatment_price, backplane_has_treatment)
          x += multiplier - 1
        }
      }
    }
    return bSum
  }

  const calcFootPrices = () => {
    let fSum = 0
    const feets = ((length - 2 * (colSize + 1000 * blueprint.thickness / 2)) > 1500) ? 6 : 4
    fSum = (footHeight !== 0) && (footHeight !== 4) ? feets * w.HoherFuss : 0
    return fSum
  }

  const coverPrice = Cover.Total(length,width,board_material_price,treatment_price, has_treatment, wallMounted)
  const bottomPrice = coverPrice
  const middlePrice = Middleboard.Total(length,width,board_material_price,treatment_price, has_treatment, wallMounted)
  const middlePrices = boards.length > 1 ? (boards.length - 1) * middlePrice : 0
  const horizontalPrice = coverPrice + bottomPrice + middlePrices
  const doorPrice = calcDoorPrice()
  const verticalPrice = calcVPrices()
  const backplanePrice = calcBackplanePrices()
  const drawerPrice = calcDrawerPrices()  
  const tabelarPrice = calcTabelarPrices()
  const footPrice = calcFootPrices()
  const treatmentSetup = has_treatment * treatment_setup / 60 * w.Lohn
  return (
    Math.round(localFactor.factor * 10 * Math.round(w.Faktor * (treatmentSetup + verticalPrice + horizontalPrice + doorPrice + backplanePrice + drawerPrice + tabelarPrice + footPrice) / 10))
  )
}

class Cover {
  static CNC = function(length, width, setupMultiplicator) {
    // (Werkstueckwechsel CNC + Setup + 3 x Werkzeugwechsel + 2 x (Länge in mm + Breite in mm) / Formatieren / (1000 x 60) + 4 x (Länge in mm + Breite in mm) / Fasen / (1000 x 60) + 2 x Länge in mm / Nuten / (1000 x 60)) x CNC
    return (w.Werkstueckwechsel + w.Setup + 3 * w.Werkzeugwechsel + 2 * (length + width) / w.Formatieren / 1000 * 60 + 4 * (length + width) / w.Fasen / 1000 * 60 + 2 * length / w.Nuten / 1000 * 60) / 3600 * w.CNC
  }
  static Salary = function(length, width, treatment, has_treatment, wallMounted) {
    // ( 2 x Länge in mm x Breite in mm / Oberfläche / (1000000 x 60) + 2 x (Länge in mm + Breite in mm) / Kante / (1000 x 60) + Handling + Zuschnitt) x Lohn
    return (2 * length * width * treatment / 1000000 * has_treatment + 2 * (length + width) * w.Kante / 1000 + w.Handling + w.Zuschnitt + w.WandRegalBoden * wallMounted) / 60 * w.Lohn
  }
  static Material = function(length, width, price) {
    // Länge in mm x Breite in mm / 1000000 x PREIS MATERIAL x Faktor Verschnitt
    return length * width / 1000000 * price * w.Verschnitt
  }
  static Total = function(length, width, price, treatment, has_treatment, wallMounted) {
    const cnc = this.CNC(length, width)
    const salary = this.Salary(length, width, treatment, has_treatment, wallMounted)
    const material = this.Material(length, width, price)
    return cnc + salary + material
  }
}

class Middleboard {
  static CNC = function(length, width) {
    // (Werkstueckwechsel CNC + 2 x Setup + 3 x Werkzeugwechsel + 2 x (Länge in mm + Breite in mm) / Formatieren / (1000 x 60) + 4 x (Länge in mm + Breite in mm) / Fasen / (1000 x 60) + 4 x Länge in mm / Nuten / (1000 x 60)) x CNC
    return (2 * w.Werkstueckwechsel + 2 * w.Setup + 3 * w.Werkzeugwechsel + 2 * (length + width) / w.Formatieren / 1000 * 60 + 4 * (length + width) / w.Fasen / 1000 * 60 + 4 * length / w.Nuten / 1000 * 60) / 3600 * w.CNC
  }
  static Salary = function(length, width, treatment, has_treatment, wallMounted) {
    // ( 2 x Länge in mm x Breite in mm / Oberfläche / (1000000 x 60) + 2 x (Länge in mm + Breite in mm) / Kante / (1000 x 60) + Handling + Zuschnitt) x Lohn
    return (2 * length * width * treatment / 1000000 * has_treatment + 2 * (length + width) * w.Kante / 1000 + w.Handling + w.Zuschnitt + w.WandRegalBoden * wallMounted) / 60 * w.Lohn
  }
  static Material = function(length, width, price) {
    // Länge in mm x Breite in mm / 1000000 x PREIS MATERIAL x Faktor Verschnitt
    return length * width / 1000000 * price * w.Verschnitt
  }
  static Total = function(length, width, price, treatment, has_treatment, wallMounted) {
    const cnc = this.CNC(length, width)
    const salary = this.Salary(length, width, treatment, has_treatment, wallMounted)
    const material = this.Material(length, width, price)
    return cnc + salary + material
  }
}

class Verticalboard {
  static CNC = function (length, width) {
    // (Werkstueckwechsel CNC + Setup + 3,4 x Werkzeugwechsel + 2 x (Länge in mm + Breite in mm) / Formatieren / (1000 x 60) + 4 x (Länge in mm + Breite in mm) / Fasen / (1000 x 60) + 5 x Länge in mm / Nuten / (1000 x 60) + 20 x (Zeit pro Bohrloch) + 4 x ABRUNDEN ((Länge in mm - 250 ) / 64) x Zeit pro Bohrloch)  x CNC
    return (w.Werkstueckwechsel + w.Setup + 3.4 * w.Werkzeugwechsel + 2 * (length + width) / w.Formatieren / 1000 * 60 + 4 * (length + width) / w.Fasen / 1000 * 60 + 5 * length / w.Nuten / 1000 * 60 + 20 * w.Bohrloch + 4 * Math.floor((length - 250)/64) * w.Bohrloch) / 3600 * w.CNC
  }
  static Salary = function(length, width, treatment, has_treatment, wallMounted) {
    // ( 2 x Länge in mm x Breite in mm / Oberfläche / (1000000 x 60) + 2 x (Länge in mm + Breite in mm) / Kante / (1000 x 60) + Handling + Zuschnitt) x Lohn
    return (2 * length * width * treatment / 1000000 * has_treatment + 2 * (length + width) * w.Kante / 1000 + w.Handling + w.Zuschnitt + w.WandRegalSeite * wallMounted) / 60 * w.Lohn
  }
  static Material = function(length, width, price) {
    // Länge in mm x Breite in mm / 1000000 x PREIS MATERIAL x Faktor Verschnitt + 5 x Beschlag Seite
    return length * width / 1000000 * price * w.Verschnitt + 5 * w.Beschlag
  }
  static Total = function(length, width, price, treatment, has_treatment, wallMounted) {
    const cnc = this.CNC(length, width)
    const salary = this.Salary(length, width, treatment, has_treatment, wallMounted)
    const material = this.Material(length, width, price)
    return cnc + salary + material
  }
}

class Door {
  static CNC = function (length, width) {
    // (Werkstueckwechsel CNC + Setup + Werkzeugwechsel + 2 x (Länge in mm + Breite in mm) / Formatieren / (1000 x 60) + 4 x (Länge in mm + Breite in mm) / Fasen / (1000 x 60) + 5 x Länge in mm / Nuten / (1000 x 60) + 20 x (Zeit pro Bohrloch) + 4 x ABRUNDEN ((Länge in mm - 250 ) / 64) x Zeit pro Bohrloch)  x CNC
    return (w.Werkstueckwechsel + w.Setup + w.Werkzeugwechsel + 2 * (length + width) / w.Formatieren / 1000 * 60 ) / 3600 * w.CNC
  }
  static Salary = function(length, width, type, treatment, has_treatment) {
    // ( 2 x Länge in mm x Breite in mm / Oberfläche / (1000000 x 60) + 2 x (Länge in mm + Breite in mm) / Kante / (1000 x 60) + Handling + Zuschnitt) x Lohn
    return (2 * length * width * treatment / 1000000 * has_treatment + 2 * (length + width) * w.Kante / 1000 + w.Handling + w.Zuschnitt + w.ZeitSTVL * type + w.ZeitIL * - (type - 1)) / 60 * w.Lohn
  }
  static Material = function(length, width, type, price) {
    // Länge in mm x Breite in mm / 1000000 x PREIS MATERIAL x Faktor Verschnitt + 5 x Beschlag Seite
    return length * width / 1000000 * price * w.Verschnitt + 2 * length / 1000 * (w.AluZ + w.Klebeband) * type + 2 * width / 1000 * (w.AluFlach + w.Klebeband)
  }
  static Total = function(length, width, type, price, treatment, has_treatment) {
    const cnc = this.CNC(length, width)
    const salary = this.Salary(length, width, type, treatment, has_treatment)
    const material = this.Material(length, width, type, price)
    return cnc + salary + material
  }
}

class Backplane {
  static CNC = function (length, width) {
    // (Werkstueckwechsel CNC + Setup + 3,4 x Werkzeugwechsel + 2 x (Länge in mm + Breite in mm) / Formatieren / (1000 x 60) + 4 x (Länge in mm + Breite in mm) / Fasen / (1000 x 60) + 5 x Länge in mm / Nuten / (1000 x 60) + 20 x (Zeit pro Bohrloch) + 4 x ABRUNDEN ((Länge in mm - 250 ) / 64) x Zeit pro Bohrloch)  x CNC
    return (w.Werkstueckwechsel + w.Setup + w.Werkzeugwechsel + 2 * (length + width) / w.Formatieren / 1000 * 60 ) / 3600 * w.CNC
  }
  static Salary = function(length, width, treatment, has_treatment) {
    // ( 2 x Länge in mm x Breite in mm / Oberfläche / (1000000 x 60) + 2 x (Länge in mm + Breite in mm) / Kante / (1000 x 60) + Handling + Zuschnitt) x Lohn
    return (2 * length * width * treatment / 1000000 * has_treatment + 2 * (length + width) * w.Kante / 1000 + w.Handling + w.Zuschnitt + w.ZeitRW ) / 60 * w.Lohn    
  }
  static Material = function(length, width, price) {
    // Länge in mm x Breite in mm / 1000000 x PREIS MATERIAL x Faktor Verschnitt + 5 x Beschlag Seite
    return length * width / 1000000 * price * w.Verschnitt
  }
  static Total = function(length, width, price, treatment, has_treatment) {
    const cnc = this.CNC(length, width)
    const salary = this.Salary(length, width, treatment, has_treatment)
    const material = this.Material(length, width, price)
    return cnc + salary + material
  }
}

class Drawer {
  static CNC = function (length, width, height, number) {
    // ((3 * Werkstückwechsel CNC + 3 * Setup + 9.4 * Werkzeugwechsel)/60 + ((Tiefe in mm + 2 * Höhe in mm + 3 * Breite in mm - 2 * 50 ) * (2 / Formatieren + 4 / Fasen) + 2 * (Tiefe in mm + Breite in mm) / Nuten) / 1000) / 60 * CNC
    return number * (((3 * w.Werkstueckwechsel + 3 * w.Setup + 9.4 * w.Werkzeugwechsel) / 60 + ((width + 3 * height + 2 * length - 2 * 50) * (2 / w.Formatieren + 4 / w.Fasen) + 2 * (width + length) / w.Nuten) / 1000) / 60 * w.CNC)
  }
  static Salary = function(length, width, height, number, treatment, body_has_treatment, front_has_treatment) {
    // ((Breite in mm + Tiefe in mm) * (Höhe in mm - 50) * Oberfläche / 1000000 * KorpushatOberfläche +  Breite in mm * Höhe in mm * Oberfläche / 1000000 * FronthatOberfläche + 3 * Handling + 3 * Zuschnitt + Zeit SK) / 60 * Lohn
    return number * ((( length + width) * (height - 50) * treatment / 1000000 * body_has_treatment * 2 + length * height * treatment / 1000000 * front_has_treatment + 3 * w.Handling + 3 * w.Zuschnitt + w.ZeitSK ) / 60 * w.Lohn)
  }
  static Material = function(length, width, height, number, frontPrice, bodyprice) {
    // (Breite in mm + Tiefe in mm) * (Höhe in mm - 50) / 1000000 * PREIS MATERIAL Korpus * Faktor Verschnitt  + Breite in mm * Höhe in mm / 1000000 * PREIS MATERIAL Front * Faktor Verschnitt + SK Beschlag
    return number * ((length + width) * (height - 50) / 1000000 * bodyprice * w.Verschnitt + length * height / 1000000 * frontPrice * w.Verschnitt + w.BeschlagSK)
  }
  static Total = function(length, width, height, number, frontPrice, bodyPrice, treatment, front_has_treatment, body_has_treatment) {
    const cnc = this.CNC(length, width, height, number)
    const salary = this.Salary(length, width, height, number, treatment, body_has_treatment, front_has_treatment)
    const material = this.Material(length, width, height, number, frontPrice, bodyPrice)
    return cnc + salary + material
  }
}

class Tabelar {
  static CNC = function(length, width, setupMultiplicator) {
    // (Werkstueckwechsel CNC + Setup + 3 x Werkzeugwechsel + 2 x (Länge in mm + Breite in mm) / Formatieren / (1000 x 60) + 4 x (Länge in mm + Breite in mm) / Fasen / (1000 x 60) + 2 x Länge in mm / Nuten / (1000 x 60)) x CNC
    return (w.Werkstueckwechsel + w.Setup + 3 * w.Werkzeugwechsel + 2 * (length + width) / w.Formatieren / 1000 * 60 + 4 * (length + width) / w.Fasen / 1000 * 60 + 2 * length / w.Nuten / 1000 * 60 + 4 * w.Bohrloch) / 3600 * w.CNC
  }
  static Salary = function(length, width, treatment, has_treatment) {
    // ( 2 x Länge in mm x Breite in mm / Oberfläche / (1000000 x 60) + 2 x (Länge in mm + Breite in mm) / Kante / (1000 x 60) + Handling + Zuschnitt) x Lohn
    return (2 * length * width * treatment / 1000000 * has_treatment + 2 * (length + width) * w.Kante / 1000 + w.Handling + w.Zuschnitt) / 60 * w.Lohn
  }
  static Material = function(length, width, price) {
    // Länge in mm x Breite in mm / 1000000 x PREIS MATERIAL x Faktor Verschnitt
    return length * width / 1000000 * price * w.Verschnitt
  }
  static Total = function(length, width, price, treatment, has_treatment) {
    const cnc = this.CNC(length, width)
    const salary = this.Salary(length, width, treatment, has_treatment)
    const material = this.Material(length, width, price)
    return cnc + salary + material
  }
}