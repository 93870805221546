import React from 'react';
import HBoard from '../HBoard';
import { blueprint } from '../../datamodels';

export default function Tabelars({scene, backplanes, boards, model, width, grid, depth, zPos, material, thickness}) {
  
  const colSize = (width - blueprint.thickness) / grid
  let totalHeight = 0
  const inset = 0.016


  return (
    boards.map((board, y) => {
      let els = []
      for(let x = 0; x < grid; x++) {
        let multiplier = 1
        const wall = board.cols[x] || {visible:true}
        const nextWall = board.cols[x + 1] || {visible:true}
        const isInset = backplanes[y]?.full === true ? 1 : (backplanes[y]?.cover[x] === undefined) ? 0 : backplanes[y].cover[x] //TODO Fix this 
        const insetDepth = depth - (inset * isInset)
        const insetZPos = zPos + (inset * isInset)
        if(wall.visible === false) {
          multiplier ++
          if(nextWall.visible === false) multiplier ++
        }
        const dWidth = colSize * multiplier - thickness
        const dataModel = model[y] || new Array(grid)
        const tabs = dataModel[x] || [0,0,0,0,0,0]
        if(board.height > 0.2 && tabs[0] === 1) els.push(<HBoard key={'Board 0'+x} name="Board 1" scene={scene} length={dWidth} xPos={x * colSize + thickness} yPos={totalHeight + board.height/2} zPos={insetZPos} depth={insetDepth} thickness={thickness} material={material} visible={true} />)
        // triple
        if(board.height > 0.5 && tabs[1] === 1) els.push(<HBoard key={'Board 1'+x} name="Board 2" scene={scene} length={dWidth} xPos={x * colSize + thickness} yPos={totalHeight + board.height/3} zPos={insetZPos} depth={insetDepth} thickness={thickness} material={material} visible={true} />)
        if(board.height > 0.5 && tabs[2] === 1) els.push(<HBoard key={'Board 2'+x} name="Board 3" scene={scene} length={dWidth} xPos={x * colSize + thickness} yPos={totalHeight + board.height/3 * 2} zPos={insetZPos} depth={insetDepth} thickness={thickness} material={material} visible={true} />)
        // // quarter
        if(board.height > 0.7 && tabs[3] === 1) els.push(<HBoard key={'Board 3'+x} name="Board 4" scene={scene} length={dWidth} xPos={x * colSize + thickness} yPos={totalHeight + board.height/4} zPos={insetZPos} depth={insetDepth} thickness={thickness} material={material} visible={true} />)
        if(board.height > 0.7 && tabs[4] === 1) els.push(<HBoard key={'Board 4'+x} name="Board 5" scene={scene} length={dWidth} xPos={x * colSize + thickness} yPos={totalHeight + board.height/4 * 2} zPos={insetZPos} depth={insetDepth} thickness={thickness} material={material} visible={true} />)
        if(board.height > 0.7 && tabs[5] === 1) els.push(<HBoard key={'Board 5'+x} name="Board 6" scene={scene} length={dWidth} xPos={x * colSize + thickness} yPos={totalHeight + board.height/4 * 3} zPos={insetZPos} depth={insetDepth} thickness={thickness} material={material} visible={true} />)
        x += multiplier - 1
      }
      totalHeight += board.height
      return els
    })
  )
} 