import Drawer from './../drawer'

export default class DrawerSelector extends Drawer {

  constructor(props) {
    super(props)
    this.mouseOver = false
    this.front.material.opacity = 0.1
    this.box.material.opacity = 0.1
    this.drawer.onMouseOver = () => this.onMouseOver()
    this.drawer.onMouseOut = () => this.onMouseOut()
    this.drawer.onMouseClicked = () => this.onMouseClicked()
  }
  setMaterial(newMat) {

  }
  onMouseOver = () => {
    if (!this.mouseOver) {
      this.front.material.opacity = 0.3
      this.box.material.opacity = 0.3
      this.mouseOver = true
    }
  }
  onMouseOut = () => {
    if (this.mouseOver) {
      this.front.material.opacity = 0.1
      this.box.material.opacity = 0.1
      this.mouseOver = false
    }
  }
  onMouseClicked = () => {
    if (this.mouseOver) {
      this.props.callback()
    }
  }

}