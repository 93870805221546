import React from 'react';
import BackplaneSelector from "./backplaneSelector"
import * as THREE from  'three'


export default class BackplaneSelectors extends React.Component {

  constructor(props) {
    super(props)
    this.selectGroup_1 = new THREE.Group()
    this.selectGroup_2 = new THREE.Group()
    this.activeObject = undefined;
    this.material = this.props.material
    this.selectGroup_1.visible = this.props.editBackplanes === 1
    this.selectGroup_2.visible = this.props.editBackplanes === 2
  }

  onMouseMove = (e) => {
    const intersections = this.props.raycaster.intersectObjects([this.selectGroup_1, this.selectGroup_2], true)
    if (intersections[0]) {
      if (intersections[0].object !== this.activeObject) {
        this.clearActiveObject()
        this.activeObject = intersections[0].object
        this.activeObject.onMouseOver()
      }
      //same object, do nothing
    } else {
      this.clearActiveObject()
    }
  }

  clearActiveObject() {
    if (this.activeObject) this.activeObject.onMouseOut()
    this.activeObject = undefined
  }
  onMouseClicked = (e) => {
    const intersections = this.props.raycaster.intersectObjects([this.selectGroup_1, this.selectGroup_2], true)
    if (intersections[0]) {
      intersections[0].object.onMouseClicked()
    }
  }

  backplaneClicked = (row, col, full) => {
    this.props.callback(row, col, full) 
  }
  
  componentDidMount() {
    this.props.scene.add(this.selectGroup_1, this.selectGroup_2)
    window.addEventListener('pointermove', this.onMouseMove, false)
    window.addEventListener('pointerdown', this.onMouseClicked, false)
  }
  componentWillUnmount() {
    window.removeEventListener('pointermove', this.onMouseMove)
    window.removeEventListener('pointerdown', this.onMouseClicked)
    this.props.scene.remove(this.selectGroup_1, this.selectGroup_2)
  }

  componentDidUpdate() {
    this.selectGroup_1.visible = this.props.editBackplanes === 1
    this.selectGroup_2.visible = this.props.editBackplanes === 2
  }
  
  render() {
    const {boards, width, grid, thickness} = this.props
    let totalHeight = 0
    const colSize = width / grid
    return (
      boards.map((board,y) => {
        // <BackplaneSelector />
        const els = []
        els.push(<BackplaneSelector key={'full.'+ y} scene={this.selectGroup_1} callback={() => this.backplaneClicked(y, 1, true)} material={this.material.clone()} width={width - 2 * thickness} height={board.height - thickness} xPos={0 + thickness} yPos={totalHeight + thickness} zPos={0.009} />)
        for(let x = 0; x < grid; x++) {
          const elNum = x
          const wall = board.cols[x] || {visible: true}
          const nextWall = board.cols[x + 1] || {visible:true}
          const multiplier = wall.visible ? 1 : nextWall.visible ? 2 : 3
          els.push(<BackplaneSelector key={y+'.'+x} scene={this.selectGroup_2} callback={() => this.backplaneClicked(y, elNum, false)} material={this.material.clone()} width={colSize * multiplier - thickness} height={board.height - thickness} xPos={x * colSize + thickness} yPos={totalHeight + thickness} zPos={0.009} />)
          x += multiplier - 1
        }
        totalHeight += board.height
        return els
      })
    )
    
  }
}

