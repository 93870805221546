import React, { useRef, useEffect} from 'react';
import './print.scss'
import ThreeScene from '../../three_components/ThreeScene';
import * as THREE from 'three'
import { Link, useLocation } from 'react-router-dom';
import logo from '../../sanktjohanser_schriftzug.svg'
import {InfoBoxPrint} from './infoboxPrint'

export default function PrintView() {

  let location = useLocation()
  console.log('Location is ', location)

  const view_one = useRef()
  const view_two = useRef()
  const view_three = useRef()
  const date = new Date().toLocaleString().split(',')[0]

  useEffect(() => {
    THREE.DefaultLoadingManager.onLoad = () => {
      view_one.current.animate()
      view_two.current.animate()
      view_three.current.animate()
    }
  },[])

  return (
    <div>

      <div className="din4">
        <div className="print_logo"><img src={logo} alt="Sanktjohanser" /></div>
        <div className="threeView">
          <ThreeScene
            ref={view_one}
            editStructure={false}
            doorsVisible={true}
            drawersVisible={true}
            perspective={0}
            print={true}
          />
        </div>
        <div className="print_viewcaption">          
          Ansicht vorne
        </div>
        <InfoBoxPrint></InfoBoxPrint>
        <div className="print_footer">          
          <div className="left">sanktjohanser | +49 8846 914211 | moebel@sanktjohanser.net | www.sanktjohanser.net</div><div className="right">{date}</div>
        </div>        
        <div className="print_pagenumber">1/3</div>
      </div>

      <div className="din4">   
        <div className="print_logo"><img src={logo} alt="Sanktjohanser" /></div>
        <div className="threeView">
          <ThreeScene
            ref={view_two}
            editStructure={false}
            doorsVisible={false}
            drawersVisible={true}
            perspective={0}
            print={true}
          />
        </div>
        <div className="print_viewcaption">          
          Ansicht vorne ohne Schiebetüren
        </div>
        <InfoBoxPrint></InfoBoxPrint>
        <div className="print_footer">          
          <div className="left">sanktjohanser | +49 8846 914211 | moebel@sanktjohanser.net | www.sanktjohanser.net</div><div className="right">{date}</div>
        </div>        
        <div className="print_pagenumber">2/3</div>
      </div>

      <div className="din4">   
        <div className="print_logo"><img src={logo} alt="Sanktjohanser" /></div>
        <div className="threeView">
          <ThreeScene
            ref={view_three}
            editStructure={false}
            doorsVisible={true}
            drawersVisible={true}
            perspective={3}
            print={true}
          />
        </div>
        <InfoBoxPrint></InfoBoxPrint>
        <div className="print_viewcaption">          
          Ansicht hinten
        </div>
        <div className="print_footer">          
          <div className="left">sanktjohanser | +49 8846 914211 | moebel@sanktjohanser.net | www.sanktjohanser.net</div><div className="right">{date}</div>
        </div>
        <div className="print_pagenumber">3/3</div>
      </div>

      
      {/*<div className="din4">   
        <div className="print_logo"><img src={logo} width="250em" alt="Sanktjohanser" /></div>
        <div className="description">
        <div className="print_title">Analog</div>
        <div className="container">
          {shelfDescription}</div>
        </div>
        <div className="print_footer">          
          <div className="left">sanktjohanser | +49 8846 914211 | moebel@sanktjohanser.net | www.sanktjohanser.net</div><div className="right">{date}</div>
        </div>
        <div className="print_pagenumber">3/3</div>
      </div>
              */}

      <div className="print_controls">
        <div onClick={()=> window.print()} className="p_button">Drucken</div>
        <Link to="/konfigurator/analog" className="p_button">Abbrechen</Link>
      </div>
    </div>
  )
}