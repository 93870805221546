import React from 'react'
import './dimension_controls.scss'
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';


export function HorizontalDimension({width}) {
    return (
        <div className="hSize">
            <Button size="small" variant="outlined" className="hSize-val" disableElevation component={Link} to="/konfigurator/analog/structure/edit/width">{width*1000}&nbsp;mm</Button>
        </div>
    )
}

export function VerticalDimension({ elements, boardPositions }) {
    const boards = [...elements].reverse()

    return (
        <div className="vContainer">
                {boards.map((el, i) => <VerticalDimensionBox key={i} id={boards.length - i - 1} height={el.height - 0.019} yPos={boardPositions[i]} />)}
        </div>
    )
}

function VerticalDimensionBox(props) {
    const height = props.height
    const yPos = props.yPos
    const divStyle = {
        top: yPos + 'px'
    }
    return (
        <div className="vSize-box" style={divStyle}>
            <Button size="small" variant="outlined" className="vSize-val" disableElevation component={Link} to={`/konfigurator/analog/structure/edit/row/${props.id}`}>{height*1000}&nbsp;mm</Button>
        </div>
    )
}

export function DepthDimension({depth}) {
    return (
        <div className="dSize">
            <Button size="small" variant="outlined" className="dSize-val" disableElevation component={Link} to="/konfigurator/analog/structure/edit/depth">{depth * 1000} mm</Button>
            <div className="dSize-line"></div>
        </div>
    )
}
