import { backplaneMaterialModel, backplaneModel, blueprint, doorMaterialModel, doorModel, drawerMaterialModel, drawerModel, localFactors, shelfMaterialModel, shelfModel, tabelarModel, woodTreatments } from "../datamodels";
import { IDoor, IState } from "../global";

export const initState:IState = {
	depth:0.33,
	grid:6,
	perspective: 0,
	shadowJoint: 0,
	thickness: 0.019,
	wallMounted: false,
	width: 2.203,
	localFactor: localStorage.getItem('sj-local') ? localFactors[localStorage.getItem('sj-local') as unknown as number] : localFactors[0],
	boardToScreenPositions: [],
	material: shelfMaterialModel[3],
	treatment: woodTreatments[0],
	boards:{
		edit: false,
		list: shelfModel,
	},
	backplanes: {
		list: backplaneModel,
		edit: 0 as 0|1|2,
		visible: true,
		material: backplaneMaterialModel[0],
	},
	drawers: {
		list: drawerModel,
		visible: true,
		material: drawerMaterialModel[0],
		edit: 0 as 0|1|2|3|4,
	},
	doors: {
		edit: 0,
		list: doorModel as IDoor[],
		material: doorMaterialModel[0],
		size: 0,
		variant: 0,
		visible: true,
	},
	feet: {
		height: 0,
		heights: blueprint.footHeights,
		variant: 0,
	},
	tabelars: {
		list: tabelarModel
	}
}