import React from 'react';
import * as THREE from 'three'
import { HSelector } from '../Selector';
import { blueprint } from '../../datamodels';
import produce from 'immer';

export default class TabelarSelectors extends React.Component {

  constructor(props) {
    super(props)
    this.activeObject = undefined
    this.selectGroup = new THREE.Group()
    this.selectGroup.visible = this.props.visible
  }

  onMouseMove = (e) => {
    const intersections = this.props.raycaster.intersectObjects([this.selectGroup], true)
    if (intersections[0]) {
      if (intersections[0].object !== this.activeObject) {
        this.clearActiveObject()
        this.activeObject = intersections[0].object
        this.activeObject.onMouseOver()
      }
      //same object, do nothing
    } else {
      this.clearActiveObject()
    }
  }

  clearActiveObject() {
    if (this.activeObject) this.activeObject.onMouseOut()
    this.activeObject = undefined
  }
  onMouseClicked = (e) => {
    const intersections = this.props.raycaster.intersectObjects([this.selectGroup], true)
    if (intersections[0]) {
      intersections[0].object.onMouseClicked()
    }
  }

  tabelarClicked = (row, col, number) => {
    const tabelars = produce(this.props.model, dataModel => {
      const rowModel = dataModel[row] || new Array(this.props.grid)
      const tabs = rowModel[col] || [0,0,0,0,0,0]
      const element = tabs[number] || 0
      tabs[number] = element === 0 ? 1 : 0 
      if(dataModel[row] === undefined) dataModel[row] = new Array(this.props.grid)
      dataModel[row][col] = tabs
    })
    this.props.callback(tabelars)
  }

  componentDidMount() {
    window.addEventListener('pointermove', this.onMouseMove, false)
    window.addEventListener('pointerdown', this.onMouseClicked, false)
    this.props.scene.add(this.selectGroup)
  }
  componentWillUnmount() {
    window.removeEventListener('pointermove', this.onMouseMove, false)
    window.removeEventListener('pointerdown', this.onMouseClicked, false)
    this.props.scene.remove(this.selectGroup)
  }
  componentDidUpdate() {
    this.selectGroup.visible = this.props.visible
  }

  render() {
    const {boards,width,grid,depth,material,thickness,zPos} = this.props
    const colSize = (width - blueprint.thickness) / grid
    let totalHeight = 0
    return(
      boards.map((board,y) => {
        let els = []
        for(let x = 0; x < grid; x++) {
          let multiplier = 1
          const wall = board.cols[x] || { visible: true } //last one always visible
          const nextWall = board.cols[x + 1] || {visible: true}
          if(wall.visible === false) {
            multiplier ++
            if(nextWall.visible === false) multiplier ++
          }
          const dWidth = colSize * multiplier - thickness 
          const col = x
          // half
          if( 0.699 > board.height && board.height > 0.2) els.push(<HSelector key={'Board 0'+x} scene={this.selectGroup} length={dWidth} xPos={x * colSize + thickness} yPos={totalHeight + board.height/2} zPos={zPos} depth={depth} thickness={thickness} material={material} callback={() => this.tabelarClicked(y,col,0)} visible={true} />)
          // triple
          if(board.height > 0.5) els.push(<HSelector key={'Board 1'+x} scene={this.selectGroup} length={dWidth} xPos={x * colSize + thickness} yPos={totalHeight + board.height/3} zPos={zPos} depth={depth} thickness={thickness} material={material} callback={() => this.tabelarClicked(y,col,1)} visible={true} />)
          if(board.height > 0.5) els.push(<HSelector key={'Board 2'+x} scene={this.selectGroup} length={dWidth} xPos={x * colSize + thickness} yPos={totalHeight + board.height/3 * 2} zPos={zPos} depth={depth} thickness={thickness} material={material} callback={() => this.tabelarClicked(y,col,2)} visible={true} />)
          // quarter
          if(board.height > 0.7) els.push(<HSelector key={'Board 3'+x} scene={this.selectGroup} length={dWidth} xPos={x * colSize + thickness} yPos={totalHeight + board.height/4} zPos={zPos} depth={depth} thickness={thickness} material={material} callback={() => this.tabelarClicked(y,col,3)} visible={true} />)
          if(board.height > 0.7) els.push(<HSelector key={'Board 4'+x} scene={this.selectGroup} length={dWidth} xPos={x * colSize + thickness} yPos={totalHeight + board.height/4 * 2} zPos={zPos} depth={depth} thickness={thickness} material={material} callback={() => this.tabelarClicked(y,col,4)} visible={true} />)
          if(board.height > 0.7) els.push(<HSelector key={'Board 5'+x} scene={this.selectGroup} length={dWidth} xPos={x * colSize + thickness} yPos={totalHeight + board.height/4 * 3} zPos={zPos} depth={depth} thickness={thickness} material={material} callback={() => this.tabelarClicked(y,col,5)} visible={true} />)
          x += multiplier - 1
        }
        totalHeight += board.height
        return els
      })
    )
  }

}