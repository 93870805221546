import { IDrawerActions, IStore } from "../../global"

export const createDrawerSlice = (setProduce:any) => {
	const drawers:IDrawerActions = {
		create: function (newDrawers): void {
			setProduce((state:IStore) => {state.config.drawers.list = newDrawers})
		}
	}
	return {
		drawers
	}
}