import * as THREE from 'three'
import MultiplexFein from './../textures/multiply-edge.jpg'
import Oak from './../textures/oak.jpg'
import WalnutEuro from './../textures/walnut-euro.jpg'
import Maple from './../textures/maple.jpg'
import Birch from './../textures/birch.jpg'
import Beech from './../textures/beech.jpg'
import Cherry from './../textures/cherry.jpg'
import WalnutAmi from './../textures/walnut-ami.jpg'
import WalnutBlack from './../textures/walnut-black.jpg'


const repeat = {
    big: { x: 0.9, y: 0.7 },
    small: { x: 0.2, y: 0.7 }
}

export default function createMaterialsObject() {
    let materials = {
        0: getMaterialGroup(multiplexVeneer, Maple, 'mult maple'),
        1: getMaterialGroup(multiplexVeneer, Birch, 'mult birch'),
        2: getMaterialGroup(multiplexVeneer, Beech, 'mult beech'),
        3: getMaterialGroup(multiplexVeneer, Oak, 'mult oak'),
        4: getMaterialGroup(multiplexVeneer, Cherry, 'mult cherry'),
        5: getMaterialGroup(multiplexVeneer, WalnutAmi, 'mult walnut'),
        6: getMaterialGroup(getMultiplex, { color: 0xd5d5d5 }, 'hpl mult'),
        7: getMaterialGroup(getMultiplex, { color: 0xd5d5d5 }, 'hpl mult'),
        8: getMaterialGroup(getMultiplex, { color: 0x050505 }, 'hpl mult'),
        9: getMaterialGroup(getMultiplex, { color: 0x090909 }, 'hpl mult'),
        10: getMaterialGroup(solidWood, Maple, 'solid maple'),
        11: getMaterialGroup(solidWood, Birch, 'solid birch'),
        12: getMaterialGroup(solidWood, Beech, 'solid beech'),
        13: getMaterialGroup(solidWood, Oak, 'solid oak'),
        14: getMaterialGroup(solidWood, Cherry, 'solid cherry'),
        15: getMaterialGroup(solidWood, WalnutAmi, 'solid walnut'),
        16: getMaterialGroup(solidWood, WalnutEuro, 'solid walnut'),
        17: getMaterialGroup(solidWood, WalnutBlack, 'solid walnut'),
        hpl1: createBaseMaterial({ color: 0x000000 }, { color: 0xEFEFEF }, 'hpl'),
        hplAlu: getMaterialGroup(createBaseMaterial, { color: 0xa9a9a9 }, { color: 0x000000 }, "aluminium"),
        hplWhite: getMaterialGroup(createBaseMaterial, { color: 0xfcfaf6 }, { color: 0x000000 }, "hplWhite"),
        hplBlack: getMaterialGroup(createBaseMaterial, { color: 0x070707 }, { color: 0x000000 }, "hplBlack"), 
        hplOak: getMaterialGroup(HPLVeneer, Oak, "hplOak"), 
        hplNut: getMaterialGroup(HPLVeneer, WalnutAmi, "hplNut"), 
        solidBlack: getMaterialGroup((p) => new THREE.MeshPhongMaterial(p), { color: 0x111111, name: 'solidBlack' }),
        solidWhite: getMaterialGroup((p) => new THREE.MeshPhongMaterial(p), { color: 0xFFFFFF, name: 'solidWhite' }),
        solidAlu: getMaterialGroup((p) => new THREE.MeshPhongMaterial(p), { color: 0x999999, name: 'solidAlu' }),
        selector: new THREE.MeshBasicMaterial({ color: 0x0000FF, transparent: true }),
    }
    return materials
}

/* GROUPS
   =============================================================== */

function getMaterialGroup(func, ...param) {
    return {
        big: func(...param, repeat.big),
        small_1: func(...param, repeat.small, { x: 0.5*Math.random(), y: 0.1 }),
        small_2: func(...param, repeat.small, { x: 0.3, y: Math.random() }),
    }
}

/* SINGLE MATERIALS
   =============================================================== */

//material face directions are (r,l,t,b,f,b)
function multiplexVeneer(veneer, name = "", faceRepeat = { x: 0.9, y: 0.6 }, offset = { x: 0, y: 0 }) {
    const faceTexture = createBaseTexture(veneer, faceRepeat, offset)
    return getMultiplex({ map: faceTexture }, name)
}

function getMultiplex(face, name = "") {
    const edgeTexture = createBaseTexture(MultiplexFein, { x: 5, y: 1 })
    const edge = { map: edgeTexture }
    return createBaseMaterial(face, edge, name)
}

function HPLVeneer(veneer, name = "", faceRepeat = { x: 1, y: 0.6 }, offset = { x: 0.1, y: 0 }) {
    const faceTexture = createBaseTexture(veneer, faceRepeat, offset)
    return createBaseMaterial({ map: faceTexture }, { color: 0x000000 }, name)
}

function solidWood(material, name = "", faceRepeat = { x: 0.9, y: 0.6 }, offset = { x: 0.0, y: 0.0 }, edgeRepeat = { x: 1, y: 0.25 }) {
    const faceTex = createBaseTexture(material, faceRepeat, offset)
    const edgeTex = createBaseTexture(material, edgeRepeat)
    const face = { map: faceTex }
    const edge = { map: edgeTex }
    const side = { color: 0xbcbcbc }
    return createBaseMaterial(face, edge, name, side)
}

// Starting to DRY
// function genericMaterial(face, edge, name) {
//     const faceMat = face.map ? { map: createBaseTexture(face.map, face.repeat = { x: 1, y: 1 }, face.offset = { x: 0, y: 0 }) } : { color: face.color }
//     const edgeMat = edge.map ? { map: createBaseTexture(edge.map, edge.repeat = { x: 1, y: 1 }) } : { color: edge.color }
//     return createBaseMaterial(faceMat, edgeMat, name)
// }

/*  BASE MATERIALS
=============================================================== */

function createBaseMaterial(face, edge, name = "", side = {}) {
    const material = []
    material.name = name
    material.push(new THREE.MeshLambertMaterial({ ...edge, name: "right" }))
    material.push(new THREE.MeshLambertMaterial({ ...edge, name: "left" }))
    material.push(new THREE.MeshLambertMaterial({ ...face, name: "top" }))
    material.push(new THREE.MeshLambertMaterial({ ...face, name: "bottom" }))
    material.push(new THREE.MeshLambertMaterial({ ...edge, name: "front" }))
    material.push(new THREE.MeshLambertMaterial({ ...edge, name: "back" }))
    return material
}

function createBaseTexture(textureImage, repeat = { x: 1, y: 1 }, offset = { x: 0, y: 0 }) {
    const loader = new THREE.TextureLoader()
    const tex = loader.load(textureImage)
    tex.encoding = THREE.sRGBEncoding
    tex.anisotropy = 8
    tex.wrapS = tex.wrapT = THREE.MirroredRepeatWrapping
    tex.repeat.set(repeat.x, repeat.y)
    tex.offset.set(offset.x, offset.y)
    return tex
}