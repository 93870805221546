import React from 'react';
import * as THREE from 'three'

export default class Backplane extends React.Component {

  constructor(props) {
    super(props)
    this.planeThickness = 0.019
    this.initObject()
  }

  initObject() {
    const {material, width, height,xPos,yPos,zPos} = this.props
    this.material = material
    this.geometry  = new THREE.BoxBufferGeometry(1,1,1)
    this.geometry.rotateX(Math.PI/2)
    this.geometry.translate(0.5,0.5,0.5)
    this.box = new THREE.Mesh(this.geometry,material)
    this.scaleObject(width,height)
    this.setPos(xPos,yPos,zPos)
  }

  scaleObject(width,height) {
    this.box.scale.set(width, height, this.planeThickness)
  }

  setPos(xPos,yPos,zPos) {
    this.box.position.set(xPos || 0, yPos || 0, zPos || 0)
  }
  setMaterial(newMat) {
    this.box.material = newMat
  }

  componentDidMount() {
    this.props.scene.add(this.box)
  }
  componentDidUpdate(prevProps) {
    this.scaleObject(this.props.width, this.props.height)
    this.setPos(this.props.xPos,this.props.yPos,this.props.zPos)
    if(prevProps.material !== this.props.material) this.setMaterial(this.props.material)
  }
  componentWillUnmount() {
    this.props.scene.remove(this.box)
    this.box.geometry.dispose()
  }
 
  render() {
    return (null)
  }

}