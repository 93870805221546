import Door from './../door'


export default class DoorSelector extends Door {
  constructor(props) {
      super(props)
      this.mouseOver = false
      this.outerCube.onMouseOver = this.onMouseOver
      this.outerCube.onMouseClicked = this.onMouseClicked
      this.outerCube.onMouseOut = this.onMouseOut
  }

  componentDidMount() {
      this.outerCube.material.opacity = 0.1
      this.innerCubeUp.material.opacity = 0.0
      this.props.scene.add(this.door)
  }
  getMaterial() {
      return (
          this.props.material.clone()
      )
  }

  onMouseOver = (e) => {
      if (!this.mouseOver) {
          this.outerCube.material.opacity = 0.3
          this.mouseOver = true
      }
  }
  onMouseOut = (e) => {
      if (this.mouseOver) {
          this.outerCube.material.opacity = 0.1
          this.mouseOver = false
      }
  }
  onMouseClicked = (e) => {
      if (this.mouseOver) {
          this.props.callback()
      }
  }
}