import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from "../../store/store";
import './ApiConnector.scss'
// import { useParams } from 'react-router-dom';


export const ApiConnector = () => {

	// let {id} = useParams<{id:string}>()
	const fetchData = useStore(store => store.fetchConfig)
	const saveConfig = useStore(store => store.saveConfig)
	const [hasSaved, setHasSaved] = useState(false)
	

	const [uri, setUri] = useState(Math.floor(Math.random()*10000000))

 	// const onLoad = () => {
	// 	fetchData((uri).toString());
	// }

	const onSave = () => {
		saveConfig((uri).toString())
		setHasSaved(true)
	}

	const onChange = (e:any) => {
		const input = e.target.value
		setUri(input)
	}

	return (
		<Dialog
			open={true}
			aria-labelledby="Save Configuration Dialog"
      aria-describedby="Save Shelf Configuration"
		>
			{!hasSaved && <>
			<DialogTitle id="alert-dialog-title">
        {"Regalkonfiguration speichern?"}
      </DialogTitle>
			<DialogContent>
          <DialogContentText id="alert-dialog-description">
            Kopieren Sie den Link zu Ihrer Konfiguration um diese zu teilen.
						Notieren oder speichern Sie sich die Konfigurations-ID um diese wieder aufzurufen.
          </DialogContentText>
					<div id="uri">
						{/* <input type="text" id="uri" name="uri" onChange={onChange} value={uri} /> */}
						<TextField variant="outlined" id="uri" name="uri" onChange={onChange} value={uri} />
					</div>
        </DialogContent>
        <DialogActions>
				<Link to="/konfigurator/analog">
					<Button>Abbrechen</Button>
				</Link>
				<Button onClick={onSave}>Speichern</Button>
        </DialogActions>
				</>}
			{hasSaved && 
			<>
				<DialogTitle id="alert-dialog-title">
					{"Regalkonfiguration gespeichert"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Kopieren Sie folgenden Link zu Ihrer Konfiguration um diese zu teilen oder wieder aufzurufen.
						Notieren oder speichern Sie sich den Link als Lesezeichen.
						<br />
						<a href={`http://${window.location.hostname}/konfigurator/analog/get/${uri}`}>https://{window.location.hostname}/konfigurator/analog/get/{uri}</a>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Link to="/konfigurator/analog">
					<Button>Schließen</Button>
				</Link>
				</DialogActions>
			</>
			}
		</Dialog>
	)
}