import React, { useRef, useEffect } from 'react';
import * as THREE from 'three'
import Drawer from './drawer';
import DrawerSelectors from './selectors/drawerSelectors';
import { blueprint } from '../../datamodels';

export default function Drawers({ material, innerMaterial = new THREE.MeshBasicMaterial({color:0x333333}), visible, drawerModel, callback, thickness, scene, raycaster, boards, width, depth, grid, editDrawers }) {

  const colSize = (width - blueprint.thickness) / grid
  let totalHeight = 0

  const drawerGroup = useRef(new THREE.Group())
  useEffect(function init() {
    scene.add(drawerGroup.current)
  },[scene])

  useEffect(function toggleVisibility() {
    drawerGroup.current.visible = visible 
  },[visible])

  const drawers = boards.map((board, y) => {
    let row = []
    totalHeight += board.height
    for (let x = 0; x < grid; x++) {
      let multiplier = 1
      const wall = board.cols[x] || { visible: true } //last one always visible
      const nextWall = board.cols[x + 1] || {visible:true}
      if(wall.visible === false) {
        multiplier ++
        if(nextWall.visible === false) multiplier ++
      }

      const unit = drawerModel[y]?.[x] || {height: 1, number:0} //not preventing empty row yet
      
      // const dWidth = wall.visible ? colSize - thickness - 0.006 : colSize * 2 - thickness - 0.006
      const dWidth = colSize * multiplier - thickness - 2 * blueprint.shadowJoint
      const dX = x * colSize + thickness + blueprint.shadowJoint
      const dHeight = (board.height - blueprint.thickness - (unit.height + 1) * blueprint.shadowJoint) / unit.height
            
      const col = x
      for(let nr = 0; nr < unit.number; nr ++) {
        const dY = totalHeight - ((dHeight + blueprint.shadowJoint) * (nr + 1))        
        row.push(<Drawer key={nr + y + '.' + col} scene={drawerGroup.current} material={material} innerMaterial={innerMaterial} width={dWidth} height={dHeight} depth={depth} xPos={dX} yPos={dY} />)
      }
      if (!wall.visible) x += multiplier - 1
    }
    return row
  })

  return (
    <>
      {drawers}
      <DrawerSelectors editDrawers={editDrawers} callback={callback} scene={scene} drawerModel={drawerModel} raycaster={raycaster} boards={boards} width={width} depth={depth} thickness={thickness} grid={grid} />
    </>
  )

}