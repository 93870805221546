import { IStore, ITabelarActions, ITabelars } from "../../global"

export const createTablearSlice = (setProduce:any) => {
	const tabelars:ITabelarActions = {
		create: function (newTabelars: ITabelars[]): void {
			setProduce((state:IStore) => {state.config.tabelars.list = newTabelars})
		}
	}
	return {
		tabelars
	}
}