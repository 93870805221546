import React from 'react'
import PropTypes from 'prop-types'

const AppContext = React.createContext({})
const ThreeContext = React.createContext({})

export {ThreeContext}
export default AppContext 

AppContext.Provider.propTypes = {
    value: PropTypes.object
}