import React from 'react';
import * as THREE from 'three'
import VBoard from './VBoard'
import createMaterialsCollection from './Materials'
import { VSelector, HSelector } from './Selector'
import Doors from './doors/doors'
import DoorSelectors from './doors/selectors/doorSelectors'
import FakeShadow from './FakeShadow'
import Drawers from './drawers/drawers';
import Backplanes from './backplanes/backplanes'
import BackplaneSelectors from './backplanes/selectors/backplaneSelectors';
import Feet from './feet/feet';
import TabelarSelectors from './tabelars/tabelarSelectors';
import Tabelars from './tabelars/tabelars';
import Board from './Board';
import { blueprint } from '../datamodels';
import { useStore } from '../store/store';

/**
 * Ok so I have groups here that the elements will be put in
 * - context will be used here; from here passing down manually to three objects
 * - add Doors(lets see if it wouldnt be easier to combine obj and selectors at a later stage)
 */

class Analog extends React.Component {
    constructor(props) {
        super(props)

        this.activeIntersection = undefined
        this.fullGroup = new THREE.Group()
        this.fullGroup.name = "fullGroup"
        this.shelfGroup = new THREE.Group()
        this.shelfGroup.name = "shelfGroup"
        this.fullGroup.add(this.shelfGroup)
        this.rowGroup = new THREE.Group()
        this.rowGroup.name = "rowGroup"
        this.shelfGroup.add(this.rowGroup)
        this.createSelectGroups()
        this.materials = createMaterialsCollection()
    }

    // Control Groups for Selection & Raycasting
    createSelectGroups() {
        this.vSelectGroup = new THREE.Group()
        this.vSelectGroup.name = "vSelectGroup"
        this.hSelectGroup = new THREE.Group()
        this.hSelectGroup.name = "hSelectGroup"
        this.door1x1SelectGroup = new THREE.Group()
        this.door1x1SelectGroup.name = "door1x1SelectGroup"
        this.door2x1SelectGroup = new THREE.Group()
        this.door2x1SelectGroup.name = "door2x1SelectGroup"
        this.door3x1SelectGroup = new THREE.Group()
        this.door3x1SelectGroup.name = "door3x1SelectGroup"
        this.door4x1SelectGroup = new THREE.Group()
        this.door4x1SelectGroup.name = "door4x1SelectGroup"
        this.door1x2SelectGroup = new THREE.Group()
        this.door1x2SelectGroup.name = "door1x2SelectGroup"
        this.door2x2SelectGroup = new THREE.Group()
        this.door2x2SelectGroup.name = "door2x2SelectGroup"
        this.door3x2SelectGroup = new THREE.Group()
        this.door3x2SelectGroup.name = "door3x2SelectGroup"
        this.door4x2SelectGroup = new THREE.Group()
        this.door4x2SelectGroup.name = "door4x2SelectGroup"
        this.fullGroup.add(this.vSelectGroup, this.hSelectGroup, this.door1x1SelectGroup, this.door2x1SelectGroup, this.door3x1SelectGroup, this.door4x1SelectGroup, this.door1x2SelectGroup, this.door2x2SelectGroup, this.door3x2SelectGroup, this.door4x2SelectGroup)
    }

    onMouseMove = (e) => {
        this.getMouseIntersections([this.vSelectGroup, this.hSelectGroup, this.door1x1SelectGroup, this.door2x1SelectGroup, this.door3x1SelectGroup, this.door4x1SelectGroup, this.door1x2SelectGroup, this.door2x2SelectGroup, this.door3x2SelectGroup, this.door4x2SelectGroup])
    }
    onMouseClicked = (e) => {
        this.activeIntersection = undefined
        this.getMouseIntersections([this.vSelectGroup, this.hSelectGroup, this.door1x1SelectGroup, this.door2x1SelectGroup, this.door3x1SelectGroup, this.door4x1SelectGroup, this.door1x2SelectGroup, this.door2x2SelectGroup, this.door3x2SelectGroup, this.door4x2SelectGroup])
        if (this.activeIntersection && this.activeIntersection.onMouseClicked) {
            this.activeIntersection.onMouseClicked()
        }
    }

    getMouseIntersections(groupArray) {
        const hitObject = this.getFirstRaycastedObject(groupArray)
        if (!hitObject && this.activeIntersection) {
            this.clearCurrentSelection()
            return undefined
        }
        if (!hitObject) {
            return undefined
         }
        if (hitObject !== this.activeIntersection) {
            if (hitObject.onMouseOver) hitObject.onMouseOver()
            if (this.activeIntersection && this.activeIntersection.onMouseOut) this.activeIntersection.onMouseOut()
            this.activeIntersection = hitObject
        }
    }

    getFirstRaycastedObject(groupArray) {
        const intersections = this.props.raycaster.intersectObjects(groupArray, true)
        if (intersections[0]) {
            return intersections[0].object
        } else {
            return undefined
        }
    }
    clearCurrentSelection() {
        if (this.activeIntersection.onMouseOut) this.activeIntersection.onMouseOut()
        this.activeIntersection = undefined
    }

    componentDidMount() {
        const { footHeight } = this.props
        this.fullGroup.position.y = blueprint.footHeights[footHeight]
        this.props.scene.add(this.fullGroup)
        // innerRef.current.addEventListener('touchstart', this.onMouseMove, false)
        // innerRef.current.addEventListener('touchend', this.onMouseClicked, false)
        window.addEventListener('mousemove', this.onMouseMove, false)
        window.addEventListener('click', this.onMouseClicked, false)
    }

    componentWillUnmount() {
        // const { innerRef } = this.props
        console.log("Analog will unmount")
        // innerRef.current.addEventListener('touchstart', this.onMouseMove, false)
        // innerRef.current.addEventListener('touchend', this.onMouseClicked, false)
        window.removeEventListener('mousemove', this.onMouseMove)
        window.removeEventListener('click', this.onMouseClicked)
        this.props.scene.remove(this.fullGroup)
    }

    componentDidUpdate() {
        const { footHeight } = this.props
        if(blueprint.footHeights[footHeight] !== this.fullGroup.position.y) {
            this.fullGroup.position.y = blueprint.footHeights[footHeight]
            console.log("Shelf updated")
        }
    }

    // toggleDoor = (e) => this.context.toggleDoor(1, 'doors1b', 1) // This works to prevent recreation of anonymous functions on every render loop
    // toggleDoor(e) {console.log(e)}

    render() {
        const { boards, backplanes, width, depth, tabelars, doorsVisible, drawersVisible, editDrawers, drawers, setDrawers, thickness, editStructure, grid } = this.props
        // const fullScene = this.fullGroup
        const innerWidth = width - blueprint.thickness;
        const shelfScene = this.shelfGroup
        const vSelectGroup = this.vSelectGroup
        const hSelectGroup = this.hSelectGroup
        vSelectGroup.visible = hSelectGroup.visible = editStructure
        const shelfMaterial = this.materials[this.props.shelfMaterial.matReference]
        const solidMaterial = this.props.shelfMaterial.type === 0 ? this.materials[this.props.shelfMaterial.fallback] : shelfMaterial
        const doorMaterial = this.materials[this.props.doorMaterial.matReference]
        const doorInnerMaterial = this.materials[this.props.doorMaterial.innerMatReference]
        const drawerMaterial = this.props.drawerMaterial.matReference === 'ref' ? shelfMaterial : this.materials[this.props.drawerMaterial.matReference]
        const drawerInnerMaterial = this.materials[this.props.shelfMaterial.drawerInnerMaterial]
        const backplaneMaterial = this.props.backplaneMaterial.matReference === 'ref' ? solidMaterial : this.materials[this.props.backplaneMaterial.matReference]
        const selectorMaterial = this.materials.selector
        let rowHeight = 0
        this.fullGroup.position.x = - width / 2
        this.fullGroup.position.z = - depth / 2
        const colSize = innerWidth / grid
        const vLenght = (height) => height - thickness
        const vPos = (pos, height) => pos - height + thickness
        const insetDepth = depth - 0.025

        return (
            <>
                <Board key={'bottom'} name="baseBoard" scene={this.rowGroup} material={shelfMaterial.big} length={width} thickness={thickness} yPos={rowHeight} xPos={0} depth={depth} />
                {boards.map((board, i) => {
                    const height = board.height
                    const cols = board.cols
                    rowHeight += height
                    return (
                        <React.Fragment key={i}>
                            <Board key={`horizontal_${i}`} rowNum={i} scene={this.rowGroup} length={width} material={shelfMaterial.big} thickness={thickness} yPos={rowHeight} xPos={0} depth={depth} />
                            <VBoard key={'vertical_start'} rowNum={i} scene={shelfScene} material={i % 2 === 0 ? shelfMaterial.small_1 : shelfMaterial.small_2} length={height - thickness} xPos={0} yPos={rowHeight - height + thickness} zPos={0} depth={depth} thickness={thickness} visible={true} />
                            {cols.map((obj, index) => <VBoard key={`vertical_${index}`} rowNum={i} scene={shelfScene} material={index % 2 === 0 ? shelfMaterial.small_1 : shelfMaterial.small_2} length={vLenght(height)} xPos={(index + 1) * colSize} yPos={vPos(rowHeight, height)} zPos={0} depth={insetDepth} thickness={thickness} visible={obj.visible} backplaneFull={backplanes[i]?.full} />)}
                            {cols.map((obj, index) => <VSelector key={index} rowNum={i} scene={vSelectGroup} material={selectorMaterial} length={vLenght(height)} xPos={(index + 1) * colSize} yPos={vPos(rowHeight, height)} zPos={0} depth={insetDepth} callback={() => this.props.toggleBoard(i, index)} thickness={thickness} cols={cols} index={index} colSize={colSize} />)}
                            <VBoard key={'vertical_end'} rowNum={i} scene={shelfScene} material={i % 2 === 0 ? shelfMaterial.small_1 : shelfMaterial.small_2} length={vLenght(height)} xPos={innerWidth} yPos={vPos(rowHeight, height)} zPos={0} depth={depth} thickness={thickness} visible={true} />
                        </React.Fragment>
                    )
                })}
                <DoorSelectors boards={boards} material={selectorMaterial} d1x1Group={this.door1x1SelectGroup} d2x1Group={this.door2x1SelectGroup} d3x1Group={this.door3x1SelectGroup} d4x1Group={this.door4x1SelectGroup} d1x2Group={this.door1x2SelectGroup} d2x2Group={this.door2x2SelectGroup} d3x2Group={this.door3x2SelectGroup} d4x2Group={this.door4x2SelectGroup} />
                <Doors boards={this.props.boards} material={doorMaterial.small_2} innerMaterial={doorInnerMaterial.small_1}scene={shelfScene} visible={doorsVisible} />
                <Drawers scene={shelfScene} editDrawers={editDrawers} visible={drawersVisible} callback={setDrawers} drawerModel={drawers} raycaster={this.props.raycaster} material={drawerMaterial.small_2} innerMaterial={drawerInnerMaterial.small_1} boards={boards} width={width} grid={grid} depth={depth} thickness={thickness} />
                <Backplanes scene={shelfScene} material={backplaneMaterial} model={backplanes} boards={this.props.boards} width={this.props.width} grid={this.props.grid} thickness={0.008} />
                <BackplaneSelectors scene={shelfScene} callback={this.props.setBackplanes} editBackplanes={this.props.editBackplanes} model={backplanes} raycaster={this.props.raycaster} boards={this.props.boards} width={this.props.width} grid={this.props.grid} material={selectorMaterial} thickness={0.008} />
                <TabelarSelectors scene={shelfScene} model={tabelars} width={width} grid={grid} depth={insetDepth} zPos={0.01} thickness={thickness} boards={boards} material={selectorMaterial} visible={editStructure} callback={this.props.setTabelars} raycaster={this.props.raycaster} />
                <Tabelars scene={shelfScene} model={tabelars} width={width} grid={grid} depth={insetDepth} zPos={0} thickness={thickness} boards={boards} backplanes={backplanes} material={shelfMaterial.small_2} />
                {boards.length > 1 && <HSelector scene={hSelectGroup} length={width} yPos={rowHeight} xPos={0} depth={depth} thickness={thickness} material={selectorMaterial} callback={() => this.props.deleteRow()} visible={true} />}
                {blueprint.colHeight.map((d,k) => <HSelector scene={hSelectGroup} key={k} length={width} yPos={rowHeight + d} xPos={0} depth={depth} thickness={thickness} material={selectorMaterial} callback={() => this.props.createNewRow(d)} visible={true} />)}
                <Feet scene={shelfScene} variant={this.props.footVariant} wallMounted={this.props.wallMounted} heightIndex={this.props.footHeight} footHeights={blueprint.footHeights} width={width} depth={depth} boards={boards} grid={grid} thickness={thickness} />
                <FakeShadow scene={this.props.scene} depth={depth} width={width} footHeight={blueprint.footHeights[this.props.footHeight]}/>
            </>
        )
    }
}

// export default Analog
// export default React.forwardRef((props, ref) => {
//     return (
//         <Analog {...props} innerRef={ref} />
//     )
// })
const ConnectedAnalog = ({...props}) => {
    const backplanes = useStore(store => store.config.backplanes.list)
    const backplaneMaterial = useStore(store => store.config.backplanes.material)
    const boards = useStore(store => store.config.boards.list)
    const depth = useStore(store => store.config.depth)
    const doors = useStore(store => store.config.doors.list)
    const doorMaterial = useStore(store => store.config.doors.material)
    const drawerMaterial = useStore(store => store.config.drawers.material)
    const drawers = useStore(store => store.config.drawers.list)
    const editBackplanes = useStore(store => store.config.backplanes.edit)
    const editDrawers = useStore(store => store.config.drawers.edit)
    const editStructure = useStore(store => store.config.boards.edit)
    const footHeight = useStore(store => store.config.feet.height)
    const grid = useStore(store => store.config.grid)
    const tabelars = useStore(store => store.config.tabelars.list)
    const thickness = useStore(store => store.config.thickness)
    const shelfMaterial =  useStore(store => store.config.material)
    const width = useStore(store => store.config.width)
    const wallMounted = useStore(store => store.config.wallMounted)
    const footVariant = useStore(store => store.config.feet.variant)
    const createNewRow = useStore(store => store.rows.create)
    const deleteRow = useStore(store => store.rows.delete)
    const setBackplanes = useStore(store => store.backplanes.create)
    const setDrawers = useStore(store => store.drawers.create)
    const setTabelars = useStore(store => store.tabelars.create)
    const toggleBoard = useStore(store => store.rows.toggleBoard)
  
    return (<Analog 
                backplanes={backplanes}
                backplaneMaterial={backplaneMaterial}
                boards={boards}
                depth={depth}
                drawerMaterial={drawerMaterial}
                drawers={drawers}
                editBackplanes={editBackplanes}
                editDrawers={editDrawers}
                editStructure={editStructure}
                footHeight={footHeight}
                footVariant={footVariant}
                grid={grid}
                tabelars={tabelars}
                doors={doors}
                doorMaterial={doorMaterial}
                thickness={thickness}
                width={width}
                shelfMaterial={shelfMaterial}
                createNewRow={createNewRow}
                deleteRow={deleteRow}
                setBackplanes={setBackplanes}
                setDrawers={setDrawers}
                setTabelars={setTabelars}
                toggleBoard={toggleBoard}
                wallMounted={wallMounted}
                {...props}
             />)
}
export default ConnectedAnalog